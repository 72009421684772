import * as React from 'react';

export interface IProps {
  /** Children (sets content of Cell) */
  children: React.ReactNode | string;
  /** The number of columns that the cell should take up. All column numbers should be between 1 and 12, and are usually a multiple of 3 at lower breakpoints. As this is mobile-first styling, and declared breakpoint columns will override this size above that breakpoint. */
  col: number | string;
  /** The number of columns that the cell takes up above the 480px breakpoint */
  sm?: number | string;
  /** The number of columns that the cell takes up above the 600px breakpoint */
  md?: number | string;
  /** The number of columns that the cell takes up above the 840px breakpoint */
  lg?: number | string;
  /** The number of columns that the cell takes up above the 960px breakpoint */
  xl?: number | string;
  /** The number of columns that the cell takes up above the 1280px breakpoint */
  xxl?: number | string;
  /** A single string of class names to be added to the outer element of the component. If adding multiple classes, just put them in a single, space-seperated string. */
  className?: string;
}

/**
 * A cell intended to be used as a child component to a `<KiteGrid>`. Rows do not need to be declared, as once the cell width's excede 12, they will begin wrapping. The following breakpoints are used:
 *
 * **sm:** 480px, **md:** 600px, **lg:** 840px, **xl:** 960px, **xxl:** 1280px.
 *
 * [Grid design guidelines](https://company-14496.frontify.com/d/xETwq0XBaQWU/kite-web-ui-guidelines#/layout/grid-amp-breakpoints)
 *
 */
const KiteGridCell = ({
  col,
  sm,
  md,
  lg,
  xl,
  xxl,
  className,
  children,
  ...other
}: IProps) => {
  const cellClasses = [
    className,
    'kite-cell',
    `kite-cell-${col}`,
    sm && `kite-cell-${sm}-gt-sm`,
    md && `kite-cell-${md}-gt-md`,
    lg && `kite-cell-${lg}-gt-lg`,
    xl && `kite-cell-${xl}-gt-xl`,
    xxl && `kite-cell-${xxl}-gt-xxl`,
  ].join(' ');

  return (
    <div className={cellClasses} {...other}>
      {children}
    </div>
  );
};

KiteGridCell.defaultProps = {
  sm: '',
  md: '',
  lg: '',
  xl: '',
  xxl: '',
  className: '',
};

export default KiteGridCell;
