import * as React from 'react';

import './KiteProgressLoader.scss';

interface IProps {
  /**
   * The value of a percentage to show. It should be between 0 and 100, but if out of the range, the nearest limit will be shown.
   */
  percent: number;
  /**
   * If true, the color will be #fff, otherwise the default color of the spinner is #0073d1.
   */
  useLight?: boolean;
  /** Height and Width of the progress loader */
  size?: string;
  /** Show percentage number */
  showPercent?: boolean;
  /** Font size of percentage number */
  percentSize?: string;
}

/**
 * A circular loader that displays a "percent complete" number. The height and width of the spinner are both set to `100%`, so any sizing should be done by the container element, and because of the animation, it is recommended to set **both** height and width of the parent, and sizing of 80-120px looks best.  [View the design specifications](https://company-14496.frontify.com/d/xETwq0XBaQWU/kite-web-ui-guidelines#/components/loaders)
 */
const KiteProgressLoader = ({
  useLight,
  percent,
  size,
  showPercent,
  percentSize,
}: IProps) => {
  const strokeColor = useLight ? '#fff' : '#0073d1';

  const getFilteredPercent = () => {
    if (percent > 100) return 100;
    if (percent < 0) return 0;
    return percent;
  };
  const getDashOffset = cleanPercent => {
    const fullVal = 125;
    return fullVal - (cleanPercent / 100) * fullVal;
  };

  let styles = {};
  styles = size ? { ...styles, width: size, height: size } : styles;

  return (
    <div className="kite-progress" style={styles}>
      <svg className="circular" viewBox="25 25 50 50">
        <circle
          cx="50"
          cy="50"
          r="20"
          fill="none"
          strokeWidth="2"
          strokeMiterlimit="10"
          stroke={strokeColor}
          strokeDashoffset={getDashOffset(getFilteredPercent())}
          className="path"
        />
      </svg>

      {showPercent && (
        <span className="progress-percent" style={{ fontSize: percentSize }}>
          {getFilteredPercent()}%
        </span>
      )}
    </div>
  );
};

KiteProgressLoader.defaultProps = {
  useLight: false,
  size: '90px',
  showPercent: true,
  percentSize: '16px',
};

export default KiteProgressLoader;
