import * as React from 'react';

export interface ICheckboxProps {
  /** Sets the `for` attribute of the <label>, and the `id` of the <input> */
  id: string;
  /** Sets the displayed label text */
  label: string;
  /** Sets the `name` property of the <input> */
  name: string;
  /** Should state whether the checkbox is checked. This is required to have a controlled input */
  checked: boolean;
  /** Function called after the `change` event of the checkbox. This should update the `checked` property appropriately */
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => any;
  /** Sets the input attribute `disabled` to `true`, which prevents interaction and adjusts the styling */
  disabled?: boolean;
  /** A single string of class names to be added to the outer component element. If adding multiple classes, just put them in a single, space-seperated string. */
  className?: string;
  /** Props passed directly to the input */
  inputProps?: object;
}

import './KiteCheckbox.scss';

/**
 * A controlled checkbox input. The `onChange` function should update the `checked` prop.  [View the design specifications](https://company-14496.frontify.com/d/xETwq0XBaQWU/kite-web-ui-guidelines#/components/selection-controls)
 */
const KiteCheckbox = ({
  id,
  label,
  name,
  checked,
  onChange,
  disabled,
  className,
  inputProps,
}: ICheckboxProps) => (
  <label
    htmlFor={id}
    className={`kite-form-group kite-custom-control kite-custom-checkbox ${className}`}
  >
    <input
      type="checkbox"
      id={id}
      name={name}
      checked={checked}
      onChange={onChange}
      disabled={disabled}
      className="kite-custom-control-input"
      {...inputProps}
    />
    <div className="kite-custom-control-indicator" />
    <span
      className="kite-custom-control-description"
      style={{ fontWeight: 'normal' }}
    >
      {label}
    </span>
  </label>
);

KiteCheckbox.defaultProps = {
  disabled: false,
  className: '',
};

export default KiteCheckbox;
