import * as React from 'react';

export interface IQuoteProps {
  /** Children */
  children: React.ReactNode;
  /** If present, shows as a byeline below the quote. */
  footer?: string;
}

/**
 * Design treatment for the HTML `<blockquote>` element, with the option to add a byeline through the 'footer' prop.
 */
const KiteBlockquote = ({ footer, children }: IQuoteProps) => (
  <blockquote className="kite-blockquote">
    {children}

    {footer && <footer className="kite-blockquote-footer">{footer}</footer>}
  </blockquote>
);

KiteBlockquote.defaultProps = {
  footer: '',
};

export default KiteBlockquote;
