import * as React from 'react';
import classNames from 'classnames';

import KiteTooltip from '../../tooltips/KiteTooltip/KiteTooltip';

import './KitePassword.scss';
import KiteIcon from '../../icons/KiteIcon/KiteIcon';
import { oneWord } from '../../../utils/oneWord';

export interface IPasswordProps {
  /** Should state the current value of the element. This is required to have a controlled input. */
  value?: any;
  /** Sets the `for` attribute of the <label>, and the `id` of the <input> */
  id?: string;
  /** Sets the displayed label text */
  label?: string;
  /** Sets the `name` property of the <input>. */
  name?: string;
  /** If true, displays a checkmark within the input field */
  isValid?: boolean;
  /** Function called after the `change` event of the element. This should update the `value` prop appropriately. */
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => any;
  /** The errorMessage to show below the element. *Note*: The presence of this prop triggers 1) The errorMessag being shown, and 2) Error styling for the text input. */
  errorMessage?: string;
  /** Sets the input attribute `disabled` to `true`, which prevents interaction and adjusts the styling */
  disabled?: boolean;
  /** A single string of class names to be added to the outer component element. If adding multiple classes, just put them in a single, space-seperated string. */
  className?: string;
  /** Placeholder text */
  placeholder?: string;
  /** Optional margin prop, must be valid CSS */
  margin?: string;
  /** Optional maxWidth prop, must be valid CSS, set to 100% to take up the full width */
  maxWidth?: string;
  /** Content that lives inside tooltip, this may be a string or some JSX, tooltip will not show up if this prop is not passed */
  tooltip?: React.ReactNode | string;
  /** Creates a button to allow that changes the input type from 'password' to 'text' */
  enableShowPassword?: boolean;
  /** Props passed directly to the input */
  inputProps?: object;
  /** Id of the error message that will also be passed to aria-describedby on the input. If no errorId is passed this will default to label-error. */
  errorId?: string;
  /** Id of the associated DynamicHint component that will be passed to aria-describedby on the input*/
  dynamicHintId?: string;
}

export interface IPasswordState {
  showText: boolean;
}

/**
 * An `<input>` element designed to be used for passwords. When the icon on the right of the field is clicked, the `type` attribute of the input toggles between "password" and "text", which shows and hides the content while the icon changes. View the design standard [here](https://company-14496.frontify.com/d/xETwq0XBaQWU/kite-web-ui-guidelines#/components/text-fields-amp-select-menus)
 */
class KitePassword extends React.Component<IPasswordProps, IPasswordState> {
  state = {
    showText: false,
  };

  static defaultProps = {
    value: '',
    id: '',
    label: '',
    name: '',
    errorMessage: '',
    disabled: false,
    className: '',
    placeholder: '',
    margin: '',
    maxWidth: '',
    tooltip: '',
    isValid: false,
    enableShowPassword: true,
  };

  toggleText = e => {
    e.preventDefault();
    this.setState(state => ({
      showText: !state.showText,
    }));
  };

  render() {
    const {
      id,
      label,
      name,
      value,
      errorMessage,
      isValid,
      placeholder,
      onChange,
      disabled,
      className,
      margin,
      maxWidth,
      tooltip,
      inputProps,
      enableShowPassword,
      errorId,
      dynamicHintId,
    } = this.props;

    const { showText } = this.state;

    const inputType = showText ? 'text' : 'password';

    const inputClasses = classNames({
      'kite-form-control': true,
      'kite-password__input': true,
      'kite-form-control-danger': errorMessage,
      'kite-password-show': showText,
    });

    let styles = {};
    styles = margin ? { ...styles, margin } : styles;
    styles = maxWidth ? { ...styles, maxWidth } : styles;

    const getDescribedBy = () => {
      return `${
        errorMessage ? errorId || oneWord(`${label}-error`) : ''
      } ${dynamicHintId || ''}`;
    };

    return (
      <div
        className={`kite-form-group kite-password ${className}`}
        style={styles}
      >
        <div className="kite-password__label-wrapper">
          {label && (
            <label
              htmlFor={oneWord(id || name || label)}
              className="kite-password__label"
            >
              {label}
            </label>
          )}
          {tooltip && (
            <KiteTooltip
              ariaLabel={`${label} tooltip`}
              ariaControls={oneWord(id || name || label)}
            >
              {tooltip}
            </KiteTooltip>
          )}
        </div>
        <div style={{ position: 'relative' }}>
          <input
            id={oneWord(id || name || label)}
            name={name}
            type={inputType}
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            disabled={disabled}
            className={inputClasses}
            style={label ? { marginTop: '4px' } : {}}
            aria-describedby={getDescribedBy()}
            {...inputProps}
          />
          {isValid && (
            <KiteIcon
              name="checkmark"
              className="kite-password__icon-valid"
              color="#00BF1F"
            />
          )}

          {enableShowPassword && (
            <button
              className="kite-password__show-text-button"
              onClick={this.toggleText}
              onKeyDown={e => (e.keyCode === 32 ? this.toggleText(e) : null)}
            >
              <>
                <span className="kite-password__sr-only">reveal password</span>
                <KiteIcon
                  name={showText ? 'eye-cross' : 'eye'}
                  color="#0062B2"
                />
              </>
            </button>
          )}
        </div>
        {errorMessage && (
          <div
            className="kite-form-control-feedback"
            role="alert"
            id={errorId || `${oneWord(id || name || label)}-error`}
          >
            {errorMessage}
          </div>
        )}
      </div>
    );
  }
}

export default KitePassword;
