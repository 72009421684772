import * as React from 'react';

import KiteIcon from '../../icons/KiteIcon/KiteIcon';
import KiteLoader from '../../loaders/KiteLoader/KiteLoader';

import { getTypeClass, getSizeClass } from './utils/button-utils';

import './KiteButton.scss';

export interface IButtonProps {
  /** Children (Sets the content of the button) */
  children: string | React.ReactNode;
  /** Sets the appearance of the button. */
  type?: 'primary' | 'secondary' | 'outline' | 'quick-link' | 'standalone-link';
  /** Sets the min-width of the button. */
  size?: 'full' | 'xl' | 'large' | 'medium' | 'small';
  /** Called after the `click` event */
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => any;
  /** Toggle the appearance and ability to interact. */
  disabled?: boolean;
  /** A single string of class names to be added to the outer element of the component. If adding multiple classes, just put them in a single, space-seperated string. */
  className?: string;
  /** Margin defaults to '0' */
  margin?: string;
  /** leftIcon, must be a valid KiteIcon */
  leftIcon?: string;
  /** rightIcon, must be a valid KiteIcon */
  rightIcon?: string;
  /** Loading state, when true button content is loader gif */
  loading?: boolean;
  /** Minimum width of outer button */
  minWidth?: string;
  /** Maximum width of outer button */
  maxWidth?: string;
  /** HTML button type attribute, will default to 'button' is buttonType is not passed*/
  buttonType?: 'button' | 'submit' | 'reset';
  /** Can show a success or failure icon inside the button. */
  status?: '' | 'success' | 'failure';
}

/**
 * Standard button to trigger a js function. View the design specifications for the [buttons](https://company-14496.frontify.com/d/xETwq0XBaQWU/kite-web-ui-guidelines#/components/buttons) and [links](https://company-14496.frontify.com/d/xETwq0XBaQWU/kite-web-ui-guidelines#/components/links)
 */
const KiteButton = ({
  type,
  size,
  onClick,
  disabled,
  children,
  margin,
  minWidth,
  maxWidth,
  className,
  leftIcon,
  rightIcon,
  loading,
  buttonType,
  status,
  ...other
}: IButtonProps) => {
  const typeClass = getTypeClass(type);
  const sizeClass = getSizeClass(size);

  let styles: object = { margin, display: 'flex' };
  styles = minWidth ? { ...styles, minWidth } : styles;
  styles = maxWidth ? { ...styles, maxWidth } : styles;
  styles = type === 'quick-link' ? { ...styles, padding: '0' } : styles;

  const displayChildren = () => {
    if (status === 'success') {
      return <KiteIcon name="checkmark-circle-f" size="24px" color="#00BF1F" />;
    }

    if (status === 'failure') {
      return <KiteIcon name="caution-circle-f" size="24px" color="#D6312B" />;
    }

    if (loading) {
      return (
        <>
          <KiteLoader
            size="small"
            className="kite-btn__loader"
            status={status}
            buttonChild={true}
          />
          <span className="kite-sr-only">{children}</span>
        </>
      );
    }

    return (
      <>
        {leftIcon && type !== 'quick-link' ? (
          <KiteIcon
            name={leftIcon || ''}
            size="14px"
            className="kite-btn__icon kite-btn__icon--left"
            margin="0 8px 0 0"
          />
        ) : (
          <></>
        )}
        {children}
        {rightIcon && type !== 'quick-link' ? (
          <KiteIcon
            name={rightIcon || ''}
            size="14px"
            className="kite-btn__icon kite-btn__icon--right"
            margin="0 0 0 8px"
          />
        ) : (
          <></>
        )}
        {type === 'quick-link' && (
          <KiteIcon
            name="chevron-right"
            size="14px"
            color={disabled ? '#96afc1' : ''}
            className="kite-btn__icon kite-btn__icon--right"
            margin="0 0 0 8px"
          />
        )}
      </>
    );
  };

  return (
    <>
      <button
        className={`kite-btn ${className} ${sizeClass}`}
        onClick={onClick}
        disabled={disabled || loading || !!status}
        style={styles}
        type={buttonType || 'button'}
        {...other}
      >
        <span tabIndex={-1} className={`kite-btn__content ${typeClass}`}>
          {displayChildren()}
        </span>
      </button>
      {(loading || status === 'success' || status === 'failure') && (
        <span aria-live="polite" aria-atomic="true" className="kite-sr-only">
          Loading status has changed to {status || (loading && 'loading')}
        </span>
      )}
    </>
  );
};

KiteButton.defaultProps = {
  margin: '',
  className: '',
  disabled: false,
  type: 'primary',
  size: 'large',
  onClick: null,
  leftIcon: '',
  rightIcon: '',
  loading: false,
  maxWidth: '',
  minWidth: '',
};

export default KiteButton;
