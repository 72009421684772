// /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
// /* eslint-disable jsx-a11y/click-events-have-key-events */
import * as React from 'react';
const createFocusTrap = require('focus-trap');
const cssClasses = {
  ROOT: 'kite-modal',
  OPEN: 'kite-modal-open',
  ANIMATING: 'kite-modal-animating',
  BACKDROP: 'kite-modal-backdrop',
  SCROLL_LOCK: 'kite-modal-scroll-lock',
  ACCEPT_BTN: 'kite-modal-accept-btn',
  CANCEL_BTN: 'kite-modal-cancel-btn',
};

import KiteButton from '../../buttons/KiteButton/KiteButton';
import KiteIcon from '../../icons/KiteIcon/KiteIcon';

import './KiteModal.scss';

export interface IModalProps {
  /** Children */
  children?: React.ReactNode | string;
  /** An id unique to the modal, used mainly for aria attributes */
  modalId?: string;
  /** Controls toggling the display of the modal */
  canShow?: boolean;
  /** Should set the 'canShow' prop accordingly. This is called when a 'click' occurs on the closing "X" or the background. */
  onHide?: () => any;
  /** The copy to appear within the title of the modal */
  title?: string;
  /** The copy to appear within the "primary" CTA button of the modal */
  ctaCopy?: string;
  /** The function to call when a 'click' occurs on the primary CTA */
  ctaAction?: () => any;
  /** Apply loading state to Primary CTA Button. This is only supported when showCloseButton is true (default). */
  ctaLoading?: boolean;
  /** When true, use the "small" modal, as described in the design specifications */
  useSmall?: boolean;
  /** Any extra classes to add to the parent element of the component */
  className?: string;
  /** If present, shows the secondary CTA. It's value is the content within the secondary CTA button. */
  secondaryCtaCopy?: string;
  /** The function to call when a 'click' occurs on the secondary CTA */
  secondaryCtaAction?: () => any;
  /** If present, shows the tertiary CTA. It's value is the content within the tertiary CTA link-button. */
  tertiaryCtaCopy?: string;
  /** The function to call when the tertiary CTA is clicked  */
  tertiaryCtaAction?: () => any;
  /** If present, displays the modal footer, with the value of the prop set as the footer's HTML, so <a> elements can be passed. */
  footerContent?: React.ReactNode;
  /** Will not show the close button if set to false. */
  showCloseButton?: boolean;
  /** Background click will not close modal when set to true. */
  disableBackgroundClose?: boolean;
}

/**
 * The standard and small Kite modals. [View the design specifications](https://company-14496.frontify.com/d/xETwq0XBaQWU/kite-web-ui-guidelines#/components/modals)
 */
class KiteModal extends React.Component<IModalProps> {
  static defaultProps = {
    useSmall: false,
    className: '',
    secondaryCtaCopy: '',
    secondaryCtaAction: null,
    footerContent: '',
    showCloseButton: true,
    disableBackgroundClose: false,
  };

  // TODO: CHANGE TYPE
  surfaceEl: any = null;

  // TODO: CHANGE TYPE
  focusTrap: any = null;

  componentDidMount() {
    this.focusTrap = createFocusTrap(this.surfaceEl, {
      clickOutsideDeactivates: true,
    });
  }

  componentDidUpdate(prevProps: IModalProps) {
    const { canShow } = this.props;
    if (prevProps.canShow !== canShow) {
      if (canShow) {
        this.openModal();
      } else {
        this.closeModal();
      }
    }
  }

  openModal = () => {
    if (this.focusTrap) this.focusTrap.activate();
    document.body.classList.add(cssClasses.SCROLL_LOCK);
    this.surfaceEl.focus();
  };

  closeModal = () => {
    this.focusTrap.deactivate();
    document.body.classList.remove(cssClasses.SCROLL_LOCK);
  };

  getRef = (el: any, varName: string | number) => {
    this[varName] = el;
    return null;
  };

  render() {
    const {
      title,
      onHide,
      ctaCopy,
      ctaAction,
      ctaLoading,
      useSmall,
      secondaryCtaCopy,
      secondaryCtaAction,
      footerContent,
      modalId,
      children,
      canShow,
      className,
      tertiaryCtaAction,
      tertiaryCtaCopy,
      showCloseButton,
      disableBackgroundClose,
    } = this.props;

    const showClass = canShow ? 'kite-modal-open' : '';
    const smallClass = useSmall ? 'kite-modal-sm' : '';

    const titleId = `${modalId}-title`;

    return (
      <div
        className={`kite-modal ${showClass} ${className} ${smallClass}`}
        id={modalId}
        onClick={() => (disableBackgroundClose ? null : onHide && onHide())}
        tabIndex={-1}
        onKeyDown={e => (e.key === 'Escape' && onHide ? onHide() : null)}
      >
        <div className="kite-modal-backdrop" />

        {/* click event is only used for stopping propagation */}
        <div
          className="kite-modal-dialog"
          onClick={e => e.stopPropagation()}
          ref={el => this.getRef(el, 'surfaceEl')}
          role="dialog"
          aria-labelledby={titleId}
          tabIndex={-1}
        >
          <div role="document">
            {' '}
            <div className="kite-modal-header">
              {showCloseButton && (
                <button onClick={onHide} className="kite-modal__close-btn">
                  <KiteIcon
                    name="x"
                    className="kite-modal__close"
                    size="1.5rem"
                  />
                  <span className="kite-sr-only">Close dialog</span>
                </button>
              )}
            </div>
            <div
              className="kite-modal-body"
              style={footerContent ? { paddingBottom: '24px' } : {}}
            >
              {title && (
                <h1 className="kite-modal-title kite-h2" id={titleId}>
                  {title}
                </h1>
              )}
              <section className="body-content">{children}</section>
              <section className="kite-modal__action-container">
                {secondaryCtaCopy && (
                  <KiteButton
                    type="outline"
                    onClick={secondaryCtaAction}
                    aria-label="secondary call to action"
                  >
                    {secondaryCtaCopy}
                  </KiteButton>
                )}
                {ctaCopy && (
                  <KiteButton
                    className="action-button"
                    onClick={ctaAction}
                    aria-label="call to action"
                    loading={ctaLoading && showCloseButton}
                  >
                    {ctaCopy}
                  </KiteButton>
                )}
              </section>
              {tertiaryCtaCopy && (
                <section className="kite-modal__tertiary">
                  <KiteButton
                    type="standalone-link"
                    onClick={tertiaryCtaAction}
                  >
                    {tertiaryCtaCopy}
                  </KiteButton>
                </section>
              )}
            </div>
            {footerContent && (
              <div className="kite-modal-footer">{footerContent}</div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default KiteModal;
