import * as React from 'react';
import classNames from 'classnames';

import './KiteMenuIcon.scss';

export interface IMenuIconProps {
  /** If true, the hamburger appears as an "X", while if closed it's the standard hamburger. */
  isOpen: boolean;
  /** The function to call when a click event occurs on the hamburger. This should probably update the *isOpen* prop. */
  onClick: () => any;
  /** Add additional classes */
  className?: string;
  /** Change bun color */
  color?: string;
  /** Accessible label for button, defaults to 'Account Menu' */
  ariaLabel?: string;
}

/**
 * A standard, three-line "menu" icon that, when clicked, morphs into an "X". [View the design guidelines](https://company-14496.frontify.com/d/xETwq0XBaQWU/kite-web-ui-guidelines#/patterns/navigation-bar)
 */
const KiteMenuIcon = ({
  isOpen,
  onClick,
  className,
  color,
  ariaLabel,
}: IMenuIconProps) => {
  let styles = {};
  styles = color ? { ...styles, borderColor: color } : styles;

  return (
    <button
      type="button"
      className={classNames({
        'kite-hamburger': true,
        'kite-hamburger--open': isOpen,
        [className || '']: className,
      })}
      onClick={onClick}
    >
      <span className="kite-hamburger__top-bun" style={styles} />
      <span className="kite-hamburger__middle-bun" style={styles} />
      <span className="kite-hamburger__bottom-bun" style={styles} />
      <span className="kite-sr-only">
        {isOpen ? 'Close' : ariaLabel || 'Account menu'}
      </span>
    </button>
  );
};

KiteMenuIcon.defaultProps = {
  className: '',
};

export default KiteMenuIcon;
