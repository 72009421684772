import * as React from 'react';

import KiteTooltip from '../../tooltips/KiteTooltip/KiteTooltip';

import './KiteInput.scss';

import { oneWord } from '../../../utils/oneWord';

export interface IInputProps {
  /** Sets the `for` attribute of the <label>, and the `id` of the <input> */
  id?: string;
  /** Sets the displayed label text */
  label?: string;
  /** Sets the `name` property of the <input> */
  name?: string;
  /** Should state the current value of the element. This is required to have a controlled input. */
  value?: any;
  /** Sets the `type` property of the <input> */
  type?:
    | 'text'
    | 'number'
    | 'date'
    | 'datetime-local'
    | 'month'
    | 'week'
    | 'time'
    | 'tel'
    | 'password'
    | 'email';
  /** Function called after the `change` event of the element. This should update the `value` prop appropriately. */
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => any;
  /** The errorMessage to show below the element. *Note*: The presence of this prop triggers 1) The errorMessage being shown, and 2) errorMessage styling for the text input. */
  errorMessage?: string;
  /** Sets the input attribute `disabled` to `true`, which prevents interaction and adjusts the styling */
  disabled?: boolean;
  /** A single string of class names to be added to the outer component element. If adding multiple classes, just put them in a single, space-seperated string. */
  className?: string;
  /** Optional margin prop, must be valid CSS */
  margin?: string;
  /** maxWidth */
  maxWidth?: string;
  /** Content that lives inside tooltip, this may be a string or some JSX, tooltip will not show up if this prop is not passed */
  tooltip?: React.ReactNode | string;
  /** This allows you to apply props directly to the input. If using with DynamicHint */
  inputProps?: object;
  /** WILL BE DEPRECATED, use inputProps object. Placeholder text */
  placeholder?: string;
  /** WILL BE DEPRECATED, use inputProps object. Function called on blur event */
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => any;
  /** WILL BE DEPRECATED, use inputProps object. Function called on focus event */
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => any;
  /** WILL BE DEPRECATED, use inputProps object. Function called on keyDown event */
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => any;
  /** WILL BE DEPRECATED, use inputProps object. Function called on keypress event */
  onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => any;
  /** WILL BE DEPRECATED, use inputProps object. Minimum number or date */
  min?: string;
  /** WILL BE DEPRECATED, use inputProps object. Maximum number or date */
  max?: string;
  /** Id of the error message that will also be passed to aria-describedby on the input. If no errorId is passed this will default to label-error. */
  errorId?: string;
  /** Id of the associated DynamicHint component that will be passed to aria-describedby on the input*/
  dynamicHintId?: string;
}

/**
 * An `<input>` element, with `type="text"`. View the design standard [here](https://company-14496.frontify.com/d/xETwq0XBaQWU/kite-web-ui-guidelines#/components/text-fields-amp-select-menus)
 */
const KiteInput = ({
  id,
  label,
  name,
  type,
  value,
  errorMessage,
  disabled,
  margin,
  className,
  placeholder,
  onFocus,
  onBlur,
  onKeyDown,
  onKeyPress,
  onChange,
  min,
  max,
  maxWidth,
  tooltip,
  errorId,
  dynamicHintId,
  inputProps,
}: IInputProps) => {
  let styles = {};
  styles = margin ? { ...styles, margin } : styles;
  styles = maxWidth ? { ...styles, maxWidth } : styles;

  const getDescribedBy = () => {
    return `${
      errorMessage ? errorId || oneWord(`${label}-error`) : ''
    } ${dynamicHintId || ''}`;
  };

  return (
    <div className={`kite-input kite-form-group ${className}`} style={styles}>
      <div className="kite-input__label-container">
        {label && (
          <label
            htmlFor={oneWord(id || name || label)}
            className="kite-input__label"
          >
            {label}
          </label>
        )}
        {tooltip && (
          <KiteTooltip
            ariaLabel={`${label} tooltip`}
            ariaControls={oneWord(id || name || label)}
          >
            {tooltip}
          </KiteTooltip>
        )}
      </div>
      <input
        id={id || name}
        name={name}
        type={type}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        onKeyPress={onKeyPress}
        disabled={disabled}
        min={min}
        max={max}
        style={label ? { marginTop: '4px' } : {}}
        className={`
          kite-form-control
          kite-input__input
          ${errorMessage && 'kite-form-control-danger'}
        `}
        aria-describedby={getDescribedBy()}
        {...inputProps}
      />
      {errorMessage && (
        <p
          className="kite-form-control-feedback"
          id={errorId || `${oneWord(id || name || label)}-error`}
        >
          {errorMessage}
        </p>
      )}
    </div>
  );
};

KiteInput.defaultProps = {
  id: '',
  label: '',
  name: '',
  value: '',
  type: 'text',
  errorMessage: '',
  disabled: false,
  className: '',
  placeholder: '',
  onChange: null,
  onBlur: null,
  onFocus: null,
  onKeyDown: null,
  onKeyPress: null,
  margin: '',
  maxWidth: '',
  min: '',
  max: '',
  tooltip: '',
  inputProps: null,
  errorId: '',
};

export default KiteInput;
