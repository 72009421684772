import * as React from 'react';

export interface IGridProps {
  /** Children */
  children: React.ReactNode | string;
  /** Default is false. If set to `true`, spacing is removed from between child cells. */
  noSpacing?: boolean;
  /** And supplemental className(s) to add to the `<div>` */
  className?: string;
}

/**
 * The wrapper for the Kite grid system. All children should be `<KiteGridCell>` components, which use a fluid 4-column grid when `<` 840px, and a 12-column grid above that. [Grid design guidelines](https://company-14496.frontify.com/d/xETwq0XBaQWU/kite-web-ui-guidelines#/layout/grid-amp-breakpoints)
 */
const KiteGrid = ({ noSpacing, className, children, ...other }: IGridProps) => {
  const gridClasses = [
    'kite-grid',
    noSpacing ? 'kite-no-gutters' : '',
    className,
  ].join(' ');

  return (
    <div className={gridClasses} {...other}>
      {children}
    </div>
  );
};

KiteGrid.defaultProps = {
  noSpacing: false,
  className: '',
};

export default KiteGrid;
