import * as React from 'react';

import KiteFontIcon from '../icons/KiteFontIcon/KiteFontIcon';
import './KitePagination.scss';

export interface IPaginationProps {
  /** The total number of pages */
  totalPages: number;
  /** The page to show as active/selected */
  currentPage: number;
  /** Function to execute when clicking the 'right' arrow. This probably should update the view and the **currentPage** prop. */
  onNextPage: () => any;
  /** Function to execute when clicking the 'left' arrow. This probably should update the view and the **currentPage** prop. */
  onPrevPage: () => any;
  /** Function to execute when a number is clicked. Will be passed the selected page number. */
  onPageSelect: (page: number) => any;
}

const getMiddleNumbers = (
  currentPage: number,
  totalPages: number
): number[] => {
  const numbers: number[] = [];
  let startPage: number;
  let endPage: number;

  // TODO: Try to clean up the logic
  if (currentPage < 4) {
    startPage = 2;
    endPage = totalPages < 7 ? totalPages - 1 : 6;
  } else if (currentPage > totalPages - 3) {
    startPage = totalPages < 7 ? 2 : totalPages - 5;
    endPage = totalPages - 1;
  } else {
    startPage = currentPage - 2;
    endPage = currentPage + 2;
  }

  for (let i = startPage; i <= endPage; i++) {
    numbers.push(i);
  }

  return numbers;
};

/**
 * Standard pagination element, adaptable to any number of pages. As a reactive component, all functions for click events should be passed in, which should update the **currentPage** prop appropriately. [View the design specifications](https://company-14496.frontify.com/d/xETwq0XBaQWU/kite-web-ui-guidelines#/components/pagination)
 */
const KitePagination = ({
  totalPages,
  currentPage,
  onNextPage,
  onPrevPage,
  onPageSelect,
}: IPaginationProps) => {
  const midNumbers = getMiddleNumbers(currentPage, totalPages);
  return (
    <nav
      className="kite-pagination"
      style={{ textAlign: 'center' }}
      role="navigation"
      aria-label="results pagination"
    >
      <div className="page-actions">
        {currentPage > 1 && (
          <button
            type="button"
            className="page-arrow prev"
            onClick={onPrevPage}
            aria-label={`Previous page, go to page ${currentPage - 1}`}
          >
            <KiteFontIcon color="#0073d1" name="chevron-left" size="20px" />
          </button>
        )}

        <div className="page-numbers">
          <button
            type="button"
            className={`page-number ${currentPage === 1 ? 'selected' : ''}`}
            onClick={() => onPageSelect(1)}
            aria-label={`Page 1 ${currentPage === 1 ? ', current page' : ''}`}
            role="link"
          >
            1
          </button>

          {currentPage > 4 && totalPages > 7 && (
            <div className="page-ellipsis left">...</div>
          )}

          {midNumbers.map((page: number) => (
            <button
              type="button"
              key={page}
              className={`page-number ${
                currentPage === page ? 'selected' : ''
              }`}
              onClick={() => onPageSelect(page)}
              aria-label={`Page ${page}${
                currentPage === page ? ', current page' : ''
              }`}
              role="link"
            >
              {page}
            </button>
          ))}

          {currentPage < totalPages - 3 && totalPages > 7 && (
            <div className="page-ellipsis right">...</div>
          )}

          <button
            type="button"
            className={`page-number ${
              currentPage === totalPages ? 'selected' : ''
            }`}
            onClick={() => onPageSelect(totalPages)}
            aria-label={`Page ${totalPages}, last page`}
            role="link"
          >
            {totalPages}
          </button>
        </div>

        {currentPage < totalPages && (
          <button
            type="button"
            className="page-arrow next"
            onClick={onNextPage}
            aria-label={`Next page, go to page ${currentPage + 1}`}
          >
            <KiteFontIcon color="#0073d1" name="chevron-right" size="20px" />
          </button>
        )}
      </div>

      <div className="page-summary">
        {`Page ${currentPage} of ${totalPages}`}
      </div>
    </nav>
  );
};

export default KitePagination;
