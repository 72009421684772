import * as React from 'react';
import classNames from 'classnames';

import KiteCard from '../../cards/KiteCard/KiteCard';
import KiteIcon from '../../icons/KiteIcon/KiteIcon';

import './KiteMultiLinePanel.scss';

export interface IMultiPanelProps {
  /** Children (Sets the content of the panel) */
  children: React.ReactNode | string;
  /** The props controlling whether the panel is expanded or not. */
  isOpen: boolean;
  /** A function to execute when the "View More" element is clicked. This should probably toggle the value of *isOpen* */
  onClick: () => any;
  /** The text to use as the title of the card. */
  title: string;
  /** The heading level that will wrap the title, default is 3 */
  headingLevel?: 1 | 2 | 3 | 4 | 5 | 6;
  /** The content to display when the card is "closed". It's recommended that this just be a JSX element, but it could also be a string. Once *isOpen* is set to true, this summary content is hidden and the content passed as children are displayed. */
  summary?: React.ReactNode | string;
  /** Any additional classes that should be added onto the KiteCard wrapper. */
  className?: string;
  /** Text to be displayed on the panel button when the panel is closed. */
  panelButtonText?: string;
  /** Text to be displayed on the panel button when the panel is open. */
  panelOpenButtonText?: string;
  /** Customize the max height for the content drawer when open. Used panels with an extremely tall amount of content. */
  maxContentHeight?: string;
}

/**
 * A card that initially only displays the title and an arrow, but when clicked expands to display hidden content. All hidden content should be passed in as children to the component. View the design standard [here](https://company-14496.frontify.com/d/xETwq0XBaQWU/kite-web-ui-guidelines#/components/expansion-panels)
 */
const KiteMultiLinePanel = ({
  children,
  isOpen,
  onClick,
  title,
  headingLevel,
  summary,
  className,
  panelButtonText,
  panelOpenButtonText,
  maxContentHeight,
}: IMultiPanelProps) => {
  let contentStyles = {};
  if (isOpen && maxContentHeight)
    contentStyles = { ...contentStyles, maxHeight: maxContentHeight };

  const titleHeadingEl: any = React.createRef();

  const setTitleHeading = () => {
    const displayTitle = isOpen ? (
      <span>
        {title} <span className="kite-sr-only">expanded content below</span>{' '}
      </span>
    ) : (
      title
    );

    const HeadingTag: any = `h${headingLevel}`;

    return (
      <HeadingTag tabIndex={-1} ref={titleHeadingEl} className="kite-h3">
        {displayTitle}
      </HeadingTag>
    );
  };

  const handleOnClick = () => {
    !isOpen && titleHeadingEl.current.focus();
    onClick();
  };

  return (
    <KiteCard
      isActive={isOpen}
      className={classNames({
        [`kite-multi-expansion-panel ${className}`]: true,
        'kite-multi-expansion-panel--show': isOpen,
      })}
    >
      <div className="kite-expansion-container">
        <div className="kite-expansion-title-row">{setTitleHeading()}</div>

        <div className="kite-expansion-summary">{summary}</div>

        <div className="kite-expansion-content" style={contentStyles}>
          {children}
        </div>
      </div>

      <button
        type="button"
        className="kite-expansion-more-row"
        onClick={handleOnClick}
        aria-expanded={isOpen ? 'true' : 'false'}
        aria-label={isOpen ? panelOpenButtonText : panelButtonText}
      >
        <span className="kite-expansion-more-row__text">
          {isOpen ? panelOpenButtonText : panelButtonText}
          <KiteIcon
            name="chevron-down-circle-f"
            size="14px"
            className="kite-expansion-more-row__icon"
            ariaLabel="asdfasdf"
          />
        </span>
      </button>
    </KiteCard>
  );
};

KiteMultiLinePanel.defaultProps = {
  summary: '',
  className: '',
  panelButtonText: 'View More Details',
  panelOpenButtonText: 'View Less Details',
  maxContentHeight: '',
  headingLevel: 3,
};

export default KiteMultiLinePanel;
