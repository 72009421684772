import * as React from 'react';
import classNames from 'classnames';

import './KiteCard.scss';

export interface ICardProps {
  /** When set to true, after a hover or focus event, the box-shadow is increased to give the appearance of the card lifting */
  isHoverable?: boolean;
  /** When set to true, the box-shadow is increased to make the card appear more elevated */
  isActive?: boolean;
  /** A single string of class names to be added to the outer element of the component. If adding multiple classes, just put them in a single, space-seperated string. */
  className?: string;
  /** Margin, must be valid CSS */
  margin?: string;
  /** Will add an indicator to the top left corner. Indicator will be a different color depending on the type */
  indicator?: string;
  /** aria-label attribute */
  ariaLabel?: string;
  /** aria-labelledby attribute */
  ariaLabelledBy?: string;
  /** Contents that will live inside of the card */
  children: React.ReactNode | string;
}

/**
 * Standard card element. Any child elements will be passed as content. View the design specifications for the [card here](https://company-14496.frontify.com/d/xETwq0XBaQWU/kite-web-ui-guidelines#/components/cards)
 */
const KiteCard = ({
  isHoverable,
  isActive,
  children,
  className,
  margin,
  indicator,
  ariaLabel,
  ariaLabelledBy,
}: ICardProps) => {
  const cardClasses = classNames({
    'kite-card': true,
    'kite-card-hoverable': isHoverable,
    'kite-card-active': isActive,
  });

  let styles = {};
  styles = margin ? { ...styles, margin } : styles;

  return (
    <div
      className={`${cardClasses} ${className}`}
      style={styles}
      aria-label={ariaLabel}
      aria-labelledby={ariaLabelledBy}
    >
      {indicator && (
        <div
          className={`kite-card__indicator kite-card__indicator--${indicator}`}
        />
      )}
      {children}
    </div>
  );
};

KiteCard.defaultProps = {
  isHoverable: false,
  isActive: false,
  className: '',
  title: '',
  margin: '',
};

export default KiteCard;
