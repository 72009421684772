import * as React from 'react';
import classNames from 'classnames';

import KiteIcon from '../../icons/KiteIcon/KiteIcon';

import './KiteAlert.scss';

export interface IAlertProps {
  /** Type of alert, 4 options: alert (high risk), caution (low risk), info (no risk or action required), confirm (success, action complete) */
  type: 'alert' | 'caution' | 'info' | 'confirm';
  /** Page level of the alert, 3 options: global, page, and inline */
  level: 'global' | 'page' | 'inline';
  /** Alert title to be displayed in bold, THIS SHOULD NOT BE USED WITH INLINE ALERTS. */
  title?: string;
  /** Alert description to be displayed */
  description?: string;
  /** id can be used to tie the alert to a spefied input */
  id?: string;
  /** Margin property on outer element */
  margin?: string;
  /** useDark changes the outline color for page level alerts */
  useDark?: boolean;
  /** Link text to be displayed if adding a link to the alert */
  linkText?: string | Element;
  /** Function to be called when clicking on the link, can be used with internal routing systems such as React Router */
  onLinkClick?: () => any | null;
  /** Function to be called when clicking the close button */
  onClose?: () => any | null;
  /** Additional classNames for the outer element. */
  className?: string;
  /** Optional aria-label for the alert icon */
  iconLabel?: string;
}

const KiteAlert = ({
  type,
  level,
  title,
  description,
  id,
  margin,
  useDark,
  linkText,
  onLinkClick,
  onClose,
  className,
  iconLabel,
}: IAlertProps) => {
  let styles = {};
  styles = margin ? { ...styles, margin } : styles;

  const iconNames = {
    alert: 'caution-alert-f',
    caution: 'caution-circle-f',
    info: 'info-circle-f',
    confirm: 'checkmark-circle-f',
  };

  const displayIcon = iconLabel ? (
    <span className="kite-alert__icon-wrapper">
      <KiteIcon
        name={iconNames[type || 'info']}
        className={`kite-alert__icon kite-alert__icon--${type}`}
        ariaLabel={iconLabel}
      />
    </span>
  ) : (
    <span className="kite-alert__icon-wrapper">
      <KiteIcon
        name={iconNames[type || 'info']}
        className={`kite-alert__icon kite-alert__icon--${type}`}
      />
    </span>
  );

  const displayTitleAndDescription = (
    <div>
      {title && <strong className="kite-alert__title">{title}: &nbsp;</strong>}
      <span className="kite-alert__description">
        {description} &nbsp;
        {linkText && (
          <button
            onClick={onLinkClick}
            className="kite-alert__link-text"
            type="button"
            role="link"
          >
            {linkText}
          </button>
        )}
      </span>
    </div>
  );

  const displayCloseButton = onClose && (
    <button onClick={onClose} className="kite-alert__dismiss">
      <KiteIcon name="x" focusable="false" />
      <span className="kite-sr-only">dismiss</span>
    </button>
  );

  if (level === 'global') {
    return (
      <header
        className={classNames({
          [`kite-alert kite-alert__global kite-alert__global--${type}`]: true,
          'kite-alert__page--dark': useDark,
          [className || '']: className,
        })}
        style={styles}
        id={id}
        aria-label={title}
        role="banner"
        tabIndex={-1}
      >
        <div
          className="kite-alert__inner"
          style={onClose !== null ? { paddingRight: '36px' } : {}}
        >
          {displayIcon}
          {displayTitleAndDescription}
          {displayCloseButton}
        </div>
      </header>
    );
  }

  if (level === 'page') {
    return (
      <div
        className={classNames({
          [`kite-alert kite-alert__page kite-alert__page--${type}`]: true,
          'kite-alert__page--dark': useDark,
          [className || '']: className,
        })}
        style={styles}
        title={title}
        id={id}
        aria-label={title}
        role="group"
        tabIndex={-1}
      >
        <div
          className="kite-alert__inner"
          style={onClose !== null ? { paddingRight: '24px' } : {}}
        >
          <div className="kite-alert__left-bar" />
          {displayIcon}
          {displayTitleAndDescription}
          {displayCloseButton}
        </div>
      </div>
    );
  }

  if (level === 'inline') {
    return (
      <div
        className={classNames({
          [`kite-alert kite-alert__inline kite-alert__inline--${type}`]: true,
          'kite-alert__page--dark': useDark,
          [className || '']: className,
        })}
        style={styles}
        id={id}
      >
        <div className="kite-alert__inner">
          {displayIcon}
          {displayTitleAndDescription}
        </div>
      </div>
    );
  }

  return <></>;
};

KiteAlert.defaultProps = {
  type: 'info',
  level: 'page',
  title: '',
  description: '',
  id: '',
  margin: '',
  useDark: false,
  linkText: '',
  onLinkClick: null,
  onClose: null,
  className: '',
};

export default KiteAlert;
