export const getSizeClass = size => {
  const base = 'kite-btn-';

  const btnSize = {
    small: `${base}sm`,
    medium: `${base}md`,
    xl: `${base}xl`,
    full: `${base}block`,
    large: `${base}lg`,
  };

  return btnSize[size];
};

export const getTypeClass = type => {
  const base = 'kite-btn-';

  const btnType = {
    primary: `${base}primary`,
    secondary: `${base}secondary`,
    outline: `${base}outline-primary`,
    'quick-link': `${base}quick-link ${base}link`,
    'standalone-link': `${base}standalone-link ${base}link`,
  };

  return btnType[type];
};
