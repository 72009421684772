import * as React from 'react';
import './KiteDescriptionList.scss';

export interface IDescItem {
  title: string;
  description: string;
}

export interface IDescListProps {
  /** Array of item objects. Each object must contain title and description properties. */
  items?: IDescItem[];
  /** Additional classNames */
  className?: string;
}

const KiteDescriptionList = ({ items, className }: IDescListProps) => (
  <dl className={`kite-description-list ${className}`}>
    {items
      ? items.map(({ title, description }) => (
          <span key={title} className="kite-description-list-item">
            <dt>{title}</dt>
            <dd>{description}</dd>
          </span>
        ))
      : []}
  </dl>
);

KiteDescriptionList.defaultProps = {
  items: [],
  className: '',
};

export default KiteDescriptionList;
