import * as React from 'react';

import './KiteLinkText.scss';

export interface ILinkTextProps {
  children: string;
}

/** Wrap the link text in this component at it will make the text bold when the link is active. */
const KiteLinkText = ({ children }: ILinkTextProps) => (
  <span className="kite-link-text" data-value={children} tabIndex={-1}>
    {children}
  </span>
);

export default KiteLinkText;
