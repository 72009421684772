import * as React from 'react';
import classNames from 'classnames';

import KiteCard from '../../cards/KiteCard/KiteCard';
import KiteIcon from '../../icons/KiteIcon/KiteIcon';

import './KiteAccordion.scss';

export interface IRow {
  id?: string;
  title: JSX.Element | string;
  content: JSX.Element | JSX.Element[] | string;
}

export interface IAccordionProps {
  /** The title to display */
  title: JSX.Element | JSX.Element[] | string;
  /** Array of row objects that will make up the Accordion rows. Each object must contain title and content properties. */
  rows: IRow[];
  /** The index of an accordion row that will be open by default/on mount */
  defaultOpenRowIndex?: number;
  /** Minimum width on outer element */
  minWidth?: string;
  /** Maximum width on outer element */
  maxWidth?: string;
  /** Margin on outer element */
  margin?: string;
  /** Additional classNames added to outer element */
  className?: string;
  /** Maximum height for each row content drawer. Used for drawers that have extremely tall content. */
  maxContentHeight?: string;
  /** An optional function to be called when a row is clicked. It will be passed the selected row object */
  onRowClick?: (selectedRow: IRow) => any;
}

export interface IAccordionState {
  activeRow: number | null;
}

/**
 * Traditional accordion rows placed inside a Kite-style card, with a header. Each accordion row must be passed in as `<KiteAccordionRow />` children. [Accordion design guidelines](https://company-14496.frontify.com/d/xETwq0XBaQWU/kite-web-ui-guidelines#/components/accordions)
 */
class KiteAccordion extends React.Component<IAccordionProps, IAccordionState> {
  static defaultProps = {
    minWidth: '',
    maxWidth: '',
    margin: '',
    className: '',
  };

  state = {
    activeRow: null,
  };

  componentDidMount() {
    const { defaultOpenRowIndex } = this.props;
    if (defaultOpenRowIndex || defaultOpenRowIndex === 0) {
      this.setState({ activeRow: defaultOpenRowIndex });
    }
  }

  handleRowClick = index => {
    const { onRowClick, rows } = this.props;
    onRowClick && onRowClick(rows[index]);

    this.setState(state => {
      const newActiveRow = state.activeRow === index ? null : index;
      return { activeRow: newActiveRow };
    });
  };

  render() {
    const {
      title,
      rows,
      minWidth,
      maxWidth,
      margin,
      className,
      maxContentHeight,
    } = this.props;
    const { activeRow } = this.state;

    let styles = {};

    styles = minWidth ? { ...styles, minWidth } : styles;
    styles = maxWidth ? { ...styles, maxWidth } : styles;
    styles = margin ? { ...styles, margin } : styles;

    const displayRows = rows.map((row, i) => {
      const active = i === activeRow;
      let contentStyles = {};
      if (active && maxContentHeight)
        contentStyles = { ...contentStyles, maxHeight: maxContentHeight };

      const getKey = () => {
        if (row.id) return row.id;
        if (typeof row.title === 'string') return row.title;
        return i.toString();
      };

      return (
        <div
          className={classNames({
            'kite-accordion__row': true,
            'kite-accordion__row--active': active,
          })}
          key={getKey()}
          id={row.id || ''}
        >
          <button
            className="kite-accordion__row-wrapper"
            onClick={() => this.handleRowClick(i)}
            aria-expanded={active ? 'true' : 'false'}
            type="button"
          >
            <span
              role="presentation"
              className="kite-accordion__row-title-wrapper"
            >
              {typeof row.title === 'string' ? (
                <span role="presentation" className="kite-accordion__row-title">
                  {row.title}
                </span>
              ) : (
                row.title
              )}
              <KiteIcon
                name="chevron-down"
                className="kite-accordion__row-icon"
                focusable="false"
              />
            </span>
          </button>

          <div className="kite-accordion__row-content" style={contentStyles}>
            {row.content}
          </div>
        </div>
      );
    });

    return (
      <KiteCard className={`kite-accordion ${className}`}>
        {typeof title === 'string' ? (
          <h2 className="kite-accordion__title">{title}</h2>
        ) : (
          <div className="kite-accordion__title">{title}</div>
        )}
        <div className="kite-accordion__rows">{displayRows}</div>
      </KiteCard>
    );
  }
}

export default KiteAccordion;
