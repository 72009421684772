import * as React from 'react';
import classNames from 'classnames';

import './KiteSwitch.scss';

export interface ISwitchProps {
  /** Controls whether the switch is in the "on" (if true) or "off" position. As the underlying checkbox is a controlled input, this value is required. */
  isOn: boolean;
  /** This function will fire when the button is clicked and can be used to toggle the containing components state. */
  onClick: () => any;
  /** The label shown next to the switch */
  label?: string;
  /** Additional classNames */
  className?: string;
  /** Show ON and OFF text */
  showText?: boolean;
}

/**
 * A toggle switch, built on top of a checkbox. As with the other controlled inputs, there should be a `checked` and `onChange` prop present. [View the design specifications](https://company-14496.frontify.com/d/xETwq0XBaQWU/kite-web-ui-guidelines#/components/selection-controls)
 */
const KiteSwitch = ({
  label,
  isOn,
  onClick,
  className,
  showText,
}: ISwitchProps) => (
  <button
    className={classNames({
      'kite-switch': true,
      'kite-switch--on': isOn,
      [className || '']: className,
    })}
    aria-pressed={isOn}
    onClick={onClick}
    type="button"
  >
    <span className="kite-switch__inner">
      <span className="kite-switch__track">
        <span className="kite-switch__toggle" />
        {showText && (
          <>
            <span
              className="kite-switch__track-label kite-switch__track-label--off"
              aria-hidden
            >
              OFF
            </span>
            <span
              className="kite-switch__track-label kite-switch__track-label--on"
              aria-hidden
            >
              ON
            </span>
          </>
        )}
      </span>

      <span className="kite-switch__label">{label}</span>
    </span>
  </button>
);

KiteSwitch.defaultProps = {
  label: '',
  isOn: false,
  onClick: null,
  className: '',
  showText: true,
};

export default KiteSwitch;
