import * as React from 'react';
import classNames from 'classnames';

import KiteIcon from '../../icons/KiteIcon/KiteIcon';
import KiteLoader from '../../loaders/KiteLoader/KiteLoader';
import KiteCard from '../../cards/KiteCard/KiteCard';

import './KiteListTile.scss';

export interface IDropdownItem {
  label: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => any;
}

export interface IListTile {
  title: string;
  type: 'link' | 'expansion' | 'dropdown';
  image?: string;
  imageDescription?: string;
  subtext?: string;
  onTileClick?: (
    event:
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
      | React.KeyboardEvent<HTMLButtonElement>,
    title: string,
    tile: IListTile
  ) => any;
  loading?: boolean;
  icon?: React.ReactNode;
  id?: string;
  path?: string;
  expansionContent?: React.ReactNode | string;
  dropdownItems?: IDropdownItem[];
}

export interface IListTileState {
  showDropdown: string;
  activePanel: string;
}

export interface IListTileProps {
  /** Array of tile objects. These will take different shapes depending on the type of the tile. Please see the example in README for referencing which properties to put on different tile types. The onTileClick propery will be passed back 3 arguments: the event, the title, and the entire tile object. */
  tiles: IListTile[];
  /** Determines whether the list tiles are on a KiteCard or just a regular div. */
  onCard?: boolean;
  /** JSX to be rendered at the top as the headline */
  titleHeader?: React.ReactNode | string;
  /** Additional classNames applied to the outer most element */
  className?: string;
}

class KiteListTile extends React.Component<IListTileProps, IListTileState> {
  static defaultProps = {
    className: '',
    onCard: true,
    titleHeader: '',
  };

  state = {
    showDropdown: '',
    activePanel: '',
  };

  firstDropdownItem: any | null = null;

  lastDropdownItem: any | null = null;

  handleTileClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    tile: IListTile
  ) => {
    if (tile.type === 'link') {
      tile.onTileClick && tile.onTileClick(e, tile.title, tile);
    }

    if (tile.type === 'expansion') {
      this.handleExpansionClick(tile.title);
    }
  };

  handleTileKeydown = (
    e: React.KeyboardEvent<HTMLButtonElement>,
    tile: IListTile
  ) => {
    const { onTileClick, title, type } = tile;

    if (e.keyCode === 32) {
      if (type === 'expansion') {
        e.preventDefault();
        this.setState(state => ({
          activePanel: state.activePanel === title ? '' : title,
        }));
      } else {
        e.preventDefault();
        onTileClick && onTileClick(e, title, tile);
      }
    }
  };

  handleDropdownClick = (title: string): void => {
    this.setState((state: IListTileState): object => {
      const newDropdown = state.showDropdown === title ? '' : title;
      return { showDropdown: newDropdown };
    });
  };

  handleDropdownKeydown = (
    e: React.KeyboardEvent<HTMLButtonElement>,
    title: string
  ): void => {
    switch (e.keyCode) {
      case 32:
        e.preventDefault();
        this.setState((state: IListTileState): object => {
          const newDropdown = state.showDropdown === title ? '' : title;
          return { showDropdown: newDropdown };
        });
        break;

      default:
        break;
    }
  };

  handleDropdownItemClick = (
    e: React.MouseEvent<HTMLButtonElement>,
    onClick: (e: React.MouseEvent<HTMLButtonElement>) => any
  ): void => {
    e.stopPropagation();
    onClick(e);
  };

  // TODO: Change any to React.KeyboardEvent type
  handleDropdownItemKeydown = (e: any, i: number): void => {
    switch (e.keyCode) {
      // escape key
      case 27:
        e.preventDefault();
        e.stopPropagation();
        this.setState({ showDropdown: '' }, this[`dropdown_${i}`].focus());
        break;
      // tab key
      case 9:
        this.setState({ showDropdown: '' });
        break;
      // down arrow
      case 40:
        e.preventDefault();
        if (e.target.nextElementSibling) {
          e.target.nextElementSibling.focus();
        } else {
          this.firstDropdownItem.focus();
        }
        break;
      // up arrow
      case 38:
        e.preventDefault();
        if (e.target.previousElementSibling) {
          e.target.previousElementSibling.focus();
        } else {
          this.lastDropdownItem.focus();
        }
        break;
      default:
        break;
    }
  };

  handleExpansionClick = (title: string): void => {
    this.setState((state: IListTileState): object => ({
      activePanel: state.activePanel === title ? '' : title,
    }));
  };

  render() {
    const { showDropdown, activePanel } = this.state;
    const { tiles, className, onCard, titleHeader } = this.props;

    const displayTiles = tiles.map(
      (tile: IListTile, i: number): React.ReactNode => {
        const {
          image,
          title,
          subtext,
          type,
          icon,
          dropdownItems,
          expansionContent,
          loading,
          id,
        } = tile;

        const active = activePanel === title;

        let styles = {};
        if (tiles.length === 1) styles = { ...styles, borderTop: 'none' };

        if (type === 'expansion') {
          styles = { ...styles, flexDirection: 'column', cursor: 'pointer' };
          return (
            <li
              key={title}
              className="kite-list-tile__tile kite-list-tile__tile--expansion"
              style={styles}
              aria-label={title}
              aria-expanded={!!active}
            >
              <button
                className="kite-list-tile__button-wrapper"
                onClick={e => this.handleTileClick(e, tile)}
                onKeyDown={e => this.handleTileKeydown(e, tile)}
              >
                <span className="kite-list-tile__wrapper">
                  <span className="kite-list-tile__hover-bar" />
                  <span className="kite-list-tile__tile-inner">
                    <span className="kite-list-tile__tile-info">
                      {loading ? (
                        <KiteLoader size="32px" />
                      ) : (
                        <>
                          {image && <img src={image} />}
                          <span className="kite-list-tile__text-wrapper">
                            <span className="kite-list-tile__heading">
                              {title}
                            </span>
                            <p>
                              <em>{subtext}</em>
                            </p>
                          </span>
                        </>
                      )}
                    </span>
                    <KiteIcon
                      name="chevron-down-circle-f"
                      size="18px"
                      color="#0073D1"
                      transform={active ? 'rotate(180deg)' : 'rotate(0deg)'}
                      transition="all ease-in-out 0.2s"
                      className="exp-icon"
                    />
                  </span>
                </span>
              </button>
              <span
                className={classNames({
                  'kite-list-tile__exp-content': true,
                  'kite-list-tile__exp-content--open': active,
                })}
              >
                {expansionContent}
              </span>
            </li>
          );
        }

        if (type === 'dropdown') {
          return (
            <li
              key={title}
              className="kite-list-tile__tile kite-list-tile__tile--dropdown"
              style={styles}
              aria-label={title}
            >
              <div className="kite-list-tile__wrapper">
                <div className="kite-list-tile__tile-inner">
                  <div className="kite-list-tile__tile-info">
                    {loading ? (
                      <KiteLoader size="32px" />
                    ) : (
                      <>
                        {image && <img src={image} />}
                        <div className="kite-list-tile__text-wrapper">
                          <span className="kite-list-tile__heading">
                            {title}
                          </span>
                          <p>
                            <em>{subtext}</em>
                          </p>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>

              <div className="kite-list-tile__dropdown-wrapper">
                <button
                  type="button"
                  className="kite-list-tile__dropdown-button"
                  onClick={() => this.handleDropdownClick(title)}
                  onKeyDown={e => this.handleDropdownKeydown(e, title)}
                  id={title}
                  aria-label={title}
                  aria-haspopup="true"
                  aria-expanded={showDropdown === title}
                  // eslint-disable-next-line no-return-assign
                  ref={el => {
                    if (el) {
                      this[`dropdown_${i}`] = el;
                    }
                  }}
                >
                  {icon ? <>{icon}</> : <KiteIcon name="more-vert-f" />}
                </button>
                {showDropdown === title && (
                  <div className="kite-list-tile__dropdown" role="menu">
                    {dropdownItems &&
                      dropdownItems.map(
                        ({ label, onClick }, dropdownItemIndex) => (
                          <button
                            type="button"
                            className="kite-list-tile__dropdown-item"
                            key={label}
                            onClick={e =>
                              this.handleDropdownItemClick(e, onClick)
                            }
                            onKeyDown={e =>
                              this.handleDropdownItemKeydown(e, i)
                            }
                            ref={el => {
                              if (el) {
                                if (dropdownItemIndex === 0) {
                                  this.firstDropdownItem = el;
                                  this.firstDropdownItem.focus();
                                }
                                if (
                                  dropdownItemIndex ===
                                  dropdownItems.length - 1
                                ) {
                                  this.lastDropdownItem = el;
                                }
                              }
                            }}
                            role="menuitem"
                            tabIndex={-1}
                          >
                            {label}
                          </button>
                        )
                      )}
                  </div>
                )}
              </div>
            </li>
          );
        }

        styles = { ...styles, cursor: 'pointer' };
        return (
          <li
            key={id || title}
            className="kite-list-tile__tile kite-list-tile__tile--link"
            style={styles}
            aria-label={title}
          >
            <button
              className="kite-list-tile__button-wrapper"
              onClick={e => this.handleTileClick(e, tile)}
              onKeyDown={e => this.handleTileKeydown(e, tile)}
            >
              <div className="kite-list-tile__wrapper">
                <div className="kite-list-tile__hover-bar" />
                <div className="kite-list-tile__tile-inner">
                  <div className="kite-list-tile__tile-info">
                    {loading ? (
                      <KiteLoader size="32px" />
                    ) : (
                      <>
                        {image && <img src={image} />}
                        <div className="kite-list-tile__text-wrapper">
                          <span className="kite-list-tile__heading">
                            {title}
                          </span>
                          <p>
                            <em>{subtext}</em>
                          </p>
                        </div>
                      </>
                    )}
                  </div>
                  {icon ? <>{icon}</> : <KiteIcon name="chevron-right" />}
                </div>
              </div>
            </button>
          </li>
        );
      }
    );

    if (onCard) {
      return (
        <KiteCard
          className={classNames({
            'kite-list-tile': true,
            [className || '']: className,
          })}
        >
          {titleHeader ? (
            <div className="kite-list-tile__header">{titleHeader}</div>
          ) : (
            <></>
          )}
          <ul
            role="list"
            className="kite-list-tile__tiles"
            style={tiles.length === 1 ? { borderTop: '1px solid #D8DDE6' } : {}}
          >
            {displayTiles}
          </ul>
        </KiteCard>
      );
    }

    return (
      <div
        className={classNames({
          'kite-list-tile': true,
          [className || '']: className,
        })}
      >
        {titleHeader && (
          <div className="kite-list-tile__header">{titleHeader}</div>
        )}
        <ul role="list" className="kite-list-tile__tiles">
          {displayTiles}
        </ul>
      </div>
    );
  }
}

export default KiteListTile;
