/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import * as React from 'react';
import classNames from 'classnames';

import KiteIcon from '../../icons/KiteIcon/KiteIcon';

import './KiteNavExpansion.scss';

export interface INavExpProps {
  /** Content that will go inside the expansion, most likely an unodered list of NavLinkText components */
  children: React.ReactNode;
  /** Title will appear at the top of the component in the title bar */
  title: string;
  /** Additional classnames applied to outer most element */
  className: string;
  /** Use dark mode */
  useDark: boolean;
}

export interface INavExpState {
  open: boolean;
}

class KiteNavExpansion extends React.Component<INavExpProps, INavExpState> {
  static defaultProps = {
    defaultActiveLink: '',
    title: '',
    className: '',
    useDark: false,
  };

  state = {
    open: false,
  };

  handleTitleClick = () => {
    this.setState((state: INavExpState) => ({
      open: !state.open,
    }));
  };

  render() {
    const { open } = this.state;
    const { title, className, useDark, children } = this.props;

    return (
      <nav
        className={classNames({
          'kite-nav-exp': true,
          'kite-nav-exp--open': open,
          'kite-nav-exp--dark': useDark,
          [className]: className,
        })}
        onKeyDown={e =>
          e.keyCode === 27 ? this.setState({ open: false }) : null
        }
      >
        <button
          type="button"
          className="kite-nav-exp__title"
          onClick={this.handleTitleClick}
          aria-expanded={open}
        >
          <span className="kite-nav-exp__content" tabIndex={-1}>
            <span className="kite-nav-exp__title-text">{title}</span>
            <div className="kite-nav-exp__title-icon-wrapper">
              <KiteIcon
                name="chevron-down"
                className="kite-nav-exp__title-icon"
                color={useDark ? '#D8DDE6' : ''}
              />
            </div>
          </span>
        </button>

        <div
          className="kite-nav-exp__drawer kite-nav-links
        kite-nav-links--vertical"
        >
          {children}
        </div>

        <div
          className="kite-nav-exp__overlay"
          onClick={() => this.setState({ open: false })}
        />
      </nav>
    );
  }
}

export default KiteNavExpansion;
