import * as React from 'react';
import classNames from 'classnames';

import KiteIcon from '../../icons/KiteIcon/KiteIcon';

import './KiteLoader.scss';

export interface ILoaderProps {
  /** Additional classNames */
  className?: string;
  /** If true, the color will be #fff, otherwise the default color of the spinner is #0073d1. */
  useLight?: boolean;
  /** Size prop will be applied to the loader circle. Should be set to 'small', 'medium', or 'large' to follow Kite design spec. Valid CSS size is still supported as well. */
  size?: string;
  /** Will turn the loader into determinite mode. Loader will then display the percent loaded. */
  percent?:
    | 0
    | 1
    | 2
    | 3
    | 4
    | 5
    | 6
    | 7
    | 8
    | 9
    | 10
    | 11
    | 12
    | 13
    | 14
    | 15
    | 16
    | 17
    | 18
    | 19
    | 20
    | 21
    | 22
    | 23
    | 24
    | 25
    | 26
    | 27
    | 28
    | 29
    | 30
    | 31
    | 32
    | 33
    | 34
    | 35
    | 36
    | 37
    | 38
    | 39
    | 40
    | 41
    | 42
    | 43
    | 44
    | 45
    | 46
    | 47
    | 48
    | 49
    | 50
    | 51
    | 52
    | 53
    | 54
    | 55
    | 56
    | 57
    | 58
    | 59
    | 60
    | 61
    | 62
    | 63
    | 64
    | 65
    | 66
    | 67
    | 68
    | 69
    | 70
    | 71
    | 72
    | 73
    | 74
    | 75
    | 76
    | 77
    | 78
    | 79
    | 80
    | 81
    | 82
    | 83
    | 84
    | 85
    | 86
    | 87
    | 88
    | 89
    | 90
    | 91
    | 92
    | 93
    | 94
    | 95
    | 96
    | 97
    | 98
    | 99
    | 100
    | null;
  /** Set to false if you do not want to display the percent. Percent will automatically not be shown on size 'small'. */
  showPercent?: boolean;
  /** Loading state status. If no value is passed it will be in a loading state. If success or failure passed it will reflect that status. */
  status?: '' | 'success' | 'failure';
  /** Title displayed under the loader. */
  loaderTitle?: string;
  /** Secondary message displayed under the loader. */
  secondaryMessage?: string;
  /** Duration message displayed under the loader. Should tell the user how much time is remaining. */
  durationMessage?: string;
  /** Used to let the loader know it is the child of a button, (automatically set if using the loading prop of KiteButton) */
  buttonChild?: boolean;
}

/**
 *  [View the design specifications](https://company-14496.frontify.com/d/xETwq0XBaQWU/kite-web-ui-guidelines#/components/loaders)
 *
 */
class KiteLoader extends React.Component<ILoaderProps> {
  static defaultProps = {
    useLight: false,
    size: 'large',
    className: '',
    percent: null,
    showPercent: true,
    status: '',
    loaderTitle: '',
    secondaryMessage: '',
    durationMessage: '',
    buttonChild: false,
  };

  loaderTitleEl: any = React.createRef();

  componentDidUpdate(prevProps) {
    const { status, loaderTitle } = this.props;
    if (prevProps.status !== status && loaderTitle) {
      this.loaderTitleEl.current.focus();
    }
  }

  render() {
    const {
      className,
      useLight,
      size,
      percent,
      showPercent,
      loaderTitle,
      secondaryMessage,
      durationMessage,
      status,
      buttonChild,
    } = this.props;
    const getStrokeColor = () => {
      if (status === 'success') return '#00BF1F';
      if (status === 'failure') return '#D6312B';
      if (useLight) return '#fff';
      return '#0073d1';
    };

    const sizePixels = {
      small: '24px',
      medium: '40px',
      large: '80px',
    };

    const loaderSize = size && sizePixels[size] ? sizePixels[size] : size;

    let loaderStyles = {};
    loaderStyles = size
      ? { ...loaderStyles, height: loaderSize, width: loaderSize }
      : loaderStyles;

    let percentStyles: object = {
      transition: 'color 1s ease-in-out',
    };
    percentStyles =
      size === 'medium' ? { ...percentStyles, fontSize: '9px' } : percentStyles;

    const getCleanPercent = () => {
      if (percent && percent > 100) return 100;
      if (percent && percent < 0) return 0;
      return percent || 0;
    };
    const getDashArrayVal = () => 126 * (getCleanPercent() / 100);

    const displayLoader = () => {
      if (typeof percent === 'number') {
        return (
          <>
            <svg
              className="kite-loader__svg"
              viewBox="25 25 50 50"
              focusable="false"
              role="img"
              aria-label={loaderTitle ? '' : 'loading'}
              style={{ transform: 'rotate(-90deg)' }}
            >
              <circle
                className="kite-loader__circle"
                strokeWidth="2px"
                strokeLinecap="butt"
                fill="none"
                cx="50"
                cy="50"
                r="20"
                strokeDasharray={`${getDashArrayVal() || 0},200`}
                style={{
                  stroke: getStrokeColor(),
                  transition: 'stroke 1s ease-in-out',
                }}
              />
            </svg>
            {showPercent && size !== 'small' && (
              <p className="kite-loader__percent" style={percentStyles}>
                {percent}%
              </p>
            )}
          </>
        );
      }

      return (
        <>
          <svg
            className="kite-loader__svg"
            viewBox="25 25 50 50"
            focusable="false"
            role="img"
            aria-label="loading"
            aria-hidden={!!loaderTitle}
            style={{ animation: 'rotate 1.5s linear infinite' }}
          >
            <circle
              className="kite-loader__circle--indeterminate"
              cx="50"
              cy="50"
              r="20"
              fill="none"
              strokeWidth="2px"
              strokeMiterlimit="10"
              strokeLinecap="butt"
              style={{
                stroke: getStrokeColor(),
                transition: 'stroke 1s ease-in-out',
              }}
            />
          </svg>
        </>
      );
    };

    return (
      <>
        {!buttonChild && (
          <span aria-live="polite" aria-atomic="true" className="kite-sr-only">
            {status && `Loading status has changed to ${status}`}
          </span>
        )}
        <span
          className={classNames({
            'kite-loader': true,
            'kite-loader--light': useLight,
            [className || '']: className,
          })}
        >
          <span className="kite-loader__loader" style={loaderStyles}>
            <KiteIcon
              name="checkmark-circle-f"
              size={loaderSize}
              className={classNames({
                'kite-loader__success-icon ': true,
                'kite-loader__success-icon--visible': status === 'success',
              })}
              ariaLabel={status === 'success' ? 'success' : ''}
            />
            <KiteIcon
              name="caution-circle-f"
              size={loaderSize}
              className={classNames({
                'kite-loader__failure-icon ': true,
                'kite-loader__failure-icon--visible': status === 'failure',
              })}
              ariaLabel={status === 'failure' ? 'failed' : ''}
            />
            {displayLoader()}
          </span>
          {!loaderTitle && (
            <p className="kite-loader__reduced-motion">Loading</p>
          )}
          {loaderTitle && (
            <p
              className="kite-loader__title"
              ref={this.loaderTitleEl}
              tabIndex={-1}
            >
              {loaderTitle}
            </p>
          )}
          {secondaryMessage && (
            <p className="kite-loader__secondary-message">{secondaryMessage}</p>
          )}
          {durationMessage && (
            <p className="kite-loader__duration-message">{durationMessage}</p>
          )}
        </span>
      </>
    );
  }
}

export default KiteLoader;
