import * as React from 'react';
import classNames from 'classnames';

import './KiteList.scss';

export interface IListProps {
  /** Children, should be <li> tags */
  children: React.ReactNode;
  /** Whether to use an `<ol>` or `<ul>` element. */
  type?: 'ordered' | 'unordered';
  /** Instead of using numbers, use lowercase letters. Only useful if using a **type** of 'ordered'. */
  useLetters?: boolean;
  /** If true, no bullets are displayed next to each list member. Only useful if using a **type** of 'unordered'. */
  noStyles?: boolean;
  /** Add custom classNames */
  className?: string;
}

/**
 * A standard text list. There isn't much added to the basic HTML of `<ol>` or `<ul>`, as most of the styles just beneath the `.kite-typography` umbrella. Pass as children the members of list inside `<li>` elements.
 */
const KiteList = ({
  type,
  useLetters,
  noStyles,
  children,
  className,
}: IListProps) => {
  const classes = classNames({
    'kite-list': true,
    'kite-list-alpha': useLetters,
    'kite-list-unstyled': noStyles,
  });

  if (noStyles) {
    return (
      <div className="kite-typography">
        {type === 'ordered' ? (
          <ol className={classes} role="list">
            {children}
          </ol>
        ) : (
          <ul className={classes} role="list">
            {children}
          </ul>
        )}
      </div>
    );
  }

  return (
    <div className={`kite-typography ${className}`}>
      {type === 'ordered' ? (
        <ol className={classes}>{children}</ol>
      ) : (
        <ul className={classes}>{children}</ul>
      )}
    </div>
  );
};

KiteList.defaultProps = {
  type: 'unordered',
  noStyles: false,
  useLetters: false,
};

export default KiteList;
