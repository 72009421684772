import * as React from 'react';
import classNames from 'classnames';
import './KiteNavLinks.scss';

export interface INavLinksProps {
  /** This should be a ul with list items that each contain link elements. Please see example in Readme. */
  children: React.ReactNode;
  /** This will determine how the links are justified in the nav container. */
  justify?: 'flex-start' | 'center' | 'flex-end' | '';
  /** Margin for the outer most element, must be valid CSS */
  margin?: string;
  /** Max width for the outer element */
  maxWidth?: string;
  /** Additional classNames passed to component */
  className?: string;
  /** Changes the link to be displayed vertically */
  vertical?: boolean;
  /** useDark */
  useDark?: boolean;
  /** aria-label that will be passed to the nav element */
  ariaLabel?: string;
}

/** KiteNavLinks allows for easy styling of native navigation elements. Please see https://react.kiteds.com?path=/story/navigation--kitenavlinks README for details on how to use. */
const KiteNavLinks = ({
  justify,
  margin,
  maxWidth,
  className,
  vertical,
  useDark,
  ariaLabel,
  children,
}: INavLinksProps) => {
  let styles = {};
  styles = justify ? { ...styles, justifyContent: justify } : styles;
  styles = margin ? { ...styles, margin } : styles;
  styles = maxWidth ? { ...styles, maxWidth } : styles;

  return (
    <nav
      className={classNames({
        'kite-nav-links': true,
        'kite-nav-links--vertical': vertical,
        'kite-nav-links--vertical-start': vertical && justify === 'flex-start',
        'kite-nav-links--vertical-end': vertical && justify === 'flex-end',
        'kite-nav-links--dark': useDark,
        [className || '']: className,
      })}
      style={styles}
      aria-label={ariaLabel}
    >
      {children}
    </nav>
  );
};

KiteNavLinks.defaultProps = {
  justify: '',
  margin: '',
  maxWidth: '',
  className: '',
  vertical: false,
};
export default KiteNavLinks;
