import * as React from 'react';
import './KiteIcon.scss';

export interface IIconProps {
  /** Name of the icon, this can be found on the Search Icons page */
  name: string;
  /** Icon color */
  color?: string;
  /** Icon size in px, rem, em, %, this applies to height and width */
  size?: string;
  /** Margin CSS value, ex: "10px 20px 5p 15px" */
  margin?: string;
  /** Transform CSS value, ex: "rotate(90deg)" */
  transform?: string;
  /** Transition CSS value, ex: "all ease-in 0.4s" */
  transition?: string;
  /** Any additional classNames */
  className?: string;
  /** Function to be run when icon is clicked. This function can be passed the event. */
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => any;
  /** Red badge for alerting the user that they have unread notifications */
  badge?: string;
  /** Aria label used for screen readers when icon is being used as a button */
  ariaLabel?: string;
  /** Value ("true" or "false") to be passed to the focusable attribute for screen readers */
  focusable?: boolean | 'false' | 'true' | 'auto' | undefined;
}

/**
 * Latest Kite icons served from an SVGO optimized SVG sprite.
 */
const KiteIcon = ({
  name,
  color,
  size,
  margin,
  transform,
  transition,
  className,
  onClick,
  ariaLabel,
  badge,
  focusable,
}: IIconProps) => {
  let styles = {};
  if (size) styles = { ...styles, height: size, width: size };
  if (margin) styles = { ...styles, margin };

  let badgeStyles = {};
  if (badge && badge.length === 2) {
    badgeStyles = { ...badgeStyles, borderRadius: '40%', right: '-12px' };
  }
  if (badge && badge.length > 2) {
    badgeStyles = { ...badgeStyles, borderRadius: '40%', right: '-20px' };
  }

  let iconStyles: object = {
    height: '100%',
    width: '100%',
    backgroundColor: 'transparent',
  };
  if (transform) iconStyles = { ...iconStyles, transform };
  if (transition) iconStyles = { ...iconStyles, transition };
  if (color) iconStyles = { ...iconStyles, fill: color };

  return (
    <>
      {onClick ? (
        <button
          type="button"
          className={`kite-icon kite-icon__button ${className}`}
          aria-label={ariaLabel}
          onClick={onClick}
          style={styles}
        >
          {badge && (
            <span className="kite-icon__badge" style={badgeStyles}>
              {badge}
            </span>
          )}
          <svg
            className={` ki icon-${name} `}
            style={iconStyles}
            focusable="false"
            aria-hidden="true"
          >
            <use xlinkHref={`/assets/kite-icons.svg#ki-${name}`} />
          </svg>
        </button>
      ) : (
        <span className={`kite-icon ${className}`} style={styles}>
          {badge && (
            <span className="kite-icon__badge" style={badgeStyles}>
              {badge}
            </span>
          )}
          <svg
            className={`ki icon-${name} `}
            style={iconStyles}
            aria-label={ariaLabel}
            aria-hidden={ariaLabel ? 'false' : 'true'}
            role={ariaLabel ? 'img' : ''}
            focusable={focusable}
          >
            <use xlinkHref={`/assets/kite-icons.svg#ki-${name}`} />
          </svg>
        </span>
      )}
    </>
  );
};

KiteIcon.defaultProps = {
  color: '',
  size: '18px',
  margin: '',
  transform: '',
  transition: '',
  className: '',
  onClick: null,
  badge: '',
  ariaLabel: '',
};

export default KiteIcon;
