import * as React from 'react';
import classNames from 'classnames';
const FocusTrap = require('focus-trap-react');

import KiteMenuIcon from '../KiteMenuIcon/KiteMenuIcon';
import KiteButton from '../../buttons/KiteButton/KiteButton';

import './KiteNavDrawer.scss';

export interface IDrawerUser {
  name: string;
  [x: string]: any;
}

export interface IDrawerProps {
  /** Array of link objects. Each object must have a label property and can optionally have render and path properties. If a render property is passed, it will render that element in place of the label. */
  children: React.ReactNode;
  /** Function to be called when the close button is clicked. */
  onClose: () => any;
  /** Function to be called when the sign out button is clicked. This button will only appear if a user object is passed. */
  onSignOut?: (user: object) => any;
  /** If the user object contains a name property it will be displayed at the bottom of the component. */
  user?: IDrawerUser | null;
  /** If true the drawer will open, otherwise it will not be visible. */
  isOpen?: boolean;
}

const KiteNavDrawer = ({
  user,
  onClose,
  onSignOut,
  isOpen,
  children,
}: IDrawerProps) => (
  // eslint-disable-next-line jsx-a11y/interactive-supports-focus
  <div
    className={classNames({
      'kite-nav-drawer': true,
      'kite-nav-drawer--open': isOpen,
    })}
    role="dialog"
    onClick={onClose}
    onKeyDown={e => (e.keyCode === 27 ? onClose() : null)}
  >
    {isOpen && (
      <FocusTrap
        focusTrapOptions={{
          clickOutsideDeactivates: true,
          escapeDeactivates: true,
        }}
      >
        <div
          className="kite-nav-drawer__inner"
          onClick={e => e.stopPropagation()}
        >
          <KiteMenuIcon
            isOpen
            onClick={onClose}
            className="kite-nav-drawer__close-icon"
          />
          <div className="kite-nav-drawer__children">{children}</div>
          <div className="kite-nav-drawer__button-wrapper">
            {user && (
              <KiteButton
                onClick={() => onSignOut && onSignOut(user)}
                className="kite-sign-out"
                size="full"
              >
                <span>
                  Sign Out
                  {user && user.name && (
                    <span className="kite-sr-only">{user.name}</span>
                  )}
                </span>
              </KiteButton>
            )}
            {user && user.name && (
              <p className="kite-nav-drawer__user-name">{user.name}</p>
            )}
          </div>
        </div>
      </FocusTrap>
    )}
  </div>
);

KiteNavDrawer.defaultProps = {
  user: null,
  isOpen: false,
};

export default KiteNavDrawer;
