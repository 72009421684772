import * as React from 'react';
import classNames from 'classnames';
import './KiteDynamicHint.scss';

export interface IRowProps {
  copy: string;
  valid: boolean;
}

const KiteDynamicHintRow = ({ copy, valid }: IRowProps) => (
  <span className="hint-row">
    {copy}
    <svg
      className="status-icon"
      viewBox="0 0 19 19"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd">
        <circle fill={valid ? '#00BF1F' : '#d6312b'} cx="9" cy="9" r="9" />

        {valid ? (
          <path
            stroke="#FFF"
            strokeWidth="2"
            d="M3.682 9.41l2.863 2.863 6.546-6.546"
          />
        ) : (
          <g fill="#fff" transform="translate(7.000000, 3.000000)">
            <polygon id="Shape1" points="0 9.42857143 3 9.42857143 3 12 0 12" />
            <polygon id="Shape2" points="0 0 3 0 3 7.71428571 0 7.71428571" />
          </g>
        )}
      </g>
    </svg>
  </span>
);

export interface ITargetEl {
  offsetTop: number;
  scrollHeight: number;
  scrollWidth: number;
  offsetLeft: number;
}

export interface IRule {
  label: string;
  validator: (value: string) => boolean;
}

export interface IDynHintProps {
  /** An array of objects, each containing the following two properties: **label** (the text to appear), and **validator** (a function that takes that current field value as an argument and returns a boolean for whether that condition is satisfied) */
  rules: IRule[];
  /** The text that appears as the title of the hint bubble */
  rulesTitle: string;
  /** Whether to show the hint bubble */
  show: boolean;
  /** The value of the field that the hints pertain to. This is used for the validator functions in the **rules** prop */
  value: string;
  /** A css-style selector for what element the bubble should align to. e.g. '#first-name' */
  target: string;
  /** An id that will be used to connect the DynamicHint to the associated input through aria-describedby */
  id: string;
}

export interface IDynHintState {
  topPos: number;
  leftPos: number;
  mounted: boolean;
}

/**
 * A tooltip-type information bubble that is intended to be shown/hidden on focus/blur of an input field, providing a list of criteria that the field needs to pass to be valid.  However, the showing/hiding of the dynamic-hint is controlled by a prop, and the HTML element it aligns to is passed in as a prop, so it can point at and be shown anything you'd like. View the design standard [here](https://company-14496.frontify.com/d/xETwq0XBaQWU/kite-web-ui-guidelines#/components/dynamic-hints)
 */
class KiteDynamicHint extends React.Component<IDynHintProps, IDynHintState> {
  state = {
    topPos: 0,
    leftPos: 0,
    mounted: false,
  };

  targetEl: HTMLDivElement | null = null;
  hintEl: HTMLDivElement | null = null;
  isLeft = false;
  leftDisplayPosition = 0;
  topDisplayPosition = 0;
  arrowPosition = 20;

  componentDidMount() {
    this.setPositions();
    this.setState({ mounted: true });
  }

  componentDidUpdate(prevProps) {
    const { show } = this.props;
    const { mounted } = this.state;

    if (mounted === true && !prevProps.show && show) {
      this.showHintRules();
    } else if (mounted === true && prevProps.show && !show) {
      this.hideHintRules();
    }
  }

  componentWillUnmount() {
    this.setState({ mounted: false });
  }

  setPositions = () => {
    const { target } = this.props;

    this.targetEl = document.querySelector(target);

    const targetBoundingRect: {
      left: number;
      width: number;
    } = this.getTargetBoundingRect(this.targetEl);

    this.isLeft =
      window &&
      targetBoundingRect &&
      targetBoundingRect.left + targetBoundingRect.width < window.innerWidth;

    this.leftDisplayPosition = this.getLeftDisplayPosition(
      this.targetEl,
      this.hintEl,
      this.isLeft
    );

    this.topDisplayPosition = this.getTopDisplayPosition(
      this.targetEl,
      this.hintEl
    );

    this.arrowPosition = this.getArrowPosition(
      this.targetEl,
      this.topDisplayPosition
    );
  };

  getTargetBoundingRect = (targetEl: HTMLDivElement | null) => {
    if (targetEl && targetEl.getBoundingClientRect()) {
      return targetEl.getBoundingClientRect();
    } else {
      return { left: 0, width: 0 };
    }
  };

  getTopDisplayPosition = (
    targetEl: HTMLDivElement | null,
    hintEl: HTMLDivElement | null
  ) => {
    let topDisplayPosition = 0;

    if (targetEl && hintEl) {
      topDisplayPosition = targetEl.offsetTop - 40;

      if (
        window &&
        topDisplayPosition + targetEl.scrollHeight > window.innerHeight - 40
      ) {
        topDisplayPosition =
          targetEl.offsetTop + targetEl.scrollHeight - hintEl.scrollHeight + 10;
      }
    } else {
      topDisplayPosition = 0;
    }
    return topDisplayPosition;
  };

  getLeftDisplayPosition = (
    targetEl: HTMLDivElement | null,
    hintEl: HTMLDivElement | null,
    isLeft: boolean
  ) => {
    if (targetEl && isLeft) {
      return targetEl.offsetLeft + targetEl.scrollWidth + 20;
    } else if (targetEl && hintEl) {
      return targetEl.offsetLeft - hintEl.scrollWidth - 20;
    }
    return 0;
  };

  getArrowPosition = (
    targetEl: HTMLDivElement | null,
    topDisplayPosition: number
  ) => {
    if (targetEl && topDisplayPosition) {
      return (
        targetEl.offsetTop - topDisplayPosition + targetEl.scrollHeight / 2
      );
    } else {
      return 20;
    }
  };

  getArrowPositionStyles = () => ({
    [this.isLeft ? 'right' : 'left']: '100%',
    [this.isLeft ? 'borderRight' : 'borderLeft']: '10px solid #fff',
    top: this.arrowPosition,
  });

  getArrowShadowPosition = () => ({
    [this.isLeft ? 'right' : 'left']: '90%',
    top: this.arrowPosition,
    boxShadow: this.isLeft
      ? '-19px 16px 6px 0px #D8DDE6'
      : '7px -9px 6px 0px #D8DDE6',
  });

  hideHintRules = () => {
    this.setState({
      topPos: -1000,
      leftPos: -1000,
    });
  };

  showHintRules = () => {
    this.setState({
      topPos: this.topDisplayPosition,
      leftPos: this.leftDisplayPosition,
    });
  };

  render() {
    const { rules, show, rulesTitle, value, target, id, ...other } = this.props;

    const { topPos, leftPos } = this.state;

    return (
      <div
        className={classNames({
          'kite-dynamic-hint': true,
          show: show,
        })}
        id={id}
        style={{ top: topPos, left: leftPos }}
        ref={el => {
          this.hintEl = el;
        }}
        {...other}
      >
        <div className="hint-rules">
          <span className="hint-arrow" style={this.getArrowPositionStyles()} />
          <span
            className="hint-arrow-shadow"
            style={this.getArrowShadowPosition()}
          />

          <h4 className="hint-title">{rulesTitle}</h4>

          {rules.map(rule => (
            <KiteDynamicHintRow
              key={rule.label}
              copy={rule.label}
              valid={rule.validator(value)}
            />
          ))}
        </div>
      </div>
    );
  }
}

export default KiteDynamicHint;
