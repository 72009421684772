import * as React from 'react';
import classNames from 'classnames';

import KiteSingleLinePanel from '../../expansion-panels/KiteSingleLinePanel/KiteSingleLinePanel';
import KiteButton from '../../buttons/KiteButton/KiteButton';

import './KiteMultiStepAccordion.scss';

export interface IMultiAccordionStep {
  title: string;
  content: React.ReactNode | string;
  continueButtonText?: string;
  backButtonText?: string;
}

export interface IMultiAccordionProps {
  /** The title will be displayed when the Accordion is closed */
  title: string;
  /** Array of step objects that must contain title and content properties. Title must be a string, and content can be a string or a React element. */
  steps: IMultiAccordionStep[];
  /** The onClick prop is optional if you want to control the containing panel open/closed state externally. This function will be called when the title bar is clicked and will be passed the event. */
  onClick?: (title: string, event: React.MouseEvent<HTMLButtonElement>) => any;
  /** The isOpen prop goes along with the onClick prop. If you are controlling open/closed state in a containing component, isOpen is required. */
  isOpen?: boolean;
  /** Additional classNames for outer element */
  className?: string;
  /** Maximum content height for each step when the step is open. Used for steps with extremely tall content. */
  maxContentHeight?: string;
}

export interface IMultiAccordionState {
  activeStep: string;
  isOpen: boolean;
}

class KiteMultiStepAccordion extends React.Component<
  IMultiAccordionProps,
  IMultiAccordionState
> {
  static defaultProps = {
    onClick: null,
    isOpen: false,
    className: '',
    maxContentHeight: '',
  };

  state = {
    activeStep: '',
    isOpen: false,
  };

  componentDidMount() {
    const { steps } = this.props;
    this.setState({ activeStep: steps[0].title });
  }

  handleTitleClick = (title, e) => {
    const { onClick } = this.props;
    if (onClick) {
      onClick(title, e);
    } else {
      this.setState(state => ({
        isOpen: !state.isOpen,
      }));
    }
  };

  handleStepClick = title => {
    this.setState(state => {
      const newActiveStep = state.activeStep === title ? '' : title;
      return { activeStep: newActiveStep };
    });
  };

  handleBackButtonClick = (step, stepIndex) => {
    const { steps } = this.props;
    const prevStep = steps[stepIndex - 1];
    if (prevStep) {
      this.setState({ activeStep: prevStep.title });
    }
  };

  handleContinueButtonClick = (step, stepIndex) => {
    const { steps } = this.props;
    const nextStep = steps[stepIndex + 1];
    if (nextStep) {
      this.setState({ activeStep: nextStep.title });
    }
  };

  render() {
    const { title, onClick, steps, className, maxContentHeight } = this.props;

    // eslint-disable-next-line react/destructuring-assignment
    const isOpen = onClick ? this.props.isOpen : this.state.isOpen;

    const { activeStep } = this.state;

    const displaySteps = steps.map((step, stepIndex) => {
      const active = step.title === activeStep;
      let contentStyles = {};
      if (active && maxContentHeight)
        contentStyles = { ...contentStyles, maxHeight: maxContentHeight };

      return (
        <div
          className={classNames({
            'kite-multi-accordion__step': true,
            'kite-multi-accordion__step--active': active && isOpen,
          })}
          key={step.title}
        >
          <button
            type="button"
            className="kite-multi-accordion__step-title"
            onClick={() => this.handleStepClick(step.title)}
            aria-expanded={active && isOpen ? 'true' : 'false'}
            id={step.title.replace(/\s/g, '')}
            ref={el => {
              if (el && active) {
                el.focus();
              }
            }}
          >
            <span>{step.title}</span>
          </button>

          <div
            className="kite-multi-accordion__step-content"
            id={step.title}
            style={contentStyles}
            role="group"
            aria-labelledby={step.title.replace(/\s/g, '')}
          >
            {step.content}
            <div className="kite-multi-accordion__step-buttons">
              {step.backButtonText && (
                <span className="kite-multi-accordion__back-button-wrapper">
                  <KiteButton
                    onClick={() => this.handleBackButtonClick(step, stepIndex)}
                    size="medium"
                    type="secondary"
                    className="kite-multi-accordion__back-button"
                  >
                    {step.backButtonText}
                  </KiteButton>
                </span>
              )}
              {step.continueButtonText && (
                <KiteButton
                  onClick={() =>
                    this.handleContinueButtonClick(step, stepIndex)
                  }
                  size="medium"
                  className="kite-multi-accordion__continue-button"
                >
                  {step.continueButtonText}
                </KiteButton>
              )}
            </div>
          </div>
        </div>
      );
    });

    return (
      <KiteSingleLinePanel
        title={title}
        onClick={e => this.handleTitleClick(title, e)}
        isOpen={isOpen || false}
        contentPadding="0"
        className={`kite-multi-accordion ${className}`}
      >
        {displaySteps}
      </KiteSingleLinePanel>
    );
  }
}

export default KiteMultiStepAccordion;
