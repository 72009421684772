import * as React from 'react';

import './KiteBadge.scss';

export interface IBadgeProps {
  /** String that will be shown as a badge, usually a notification count or other number. */
  badge?: string;
  /** Additional classNames on outer most element. */
  className?: string;
  /** Optional screen reader only text to describe what the badge number relates to */
  qualifierText?: string;
}

const KiteBadge = ({ badge, className, qualifierText }: IBadgeProps) => {
  let badgeStyles = {};
  if (badge && badge.length === 2) {
    badgeStyles = { ...badgeStyles, borderRadius: '40%', right: '-12px' };
  }
  if (badge && badge.length > 2) {
    badgeStyles = { ...badgeStyles, borderRadius: '40%', right: '-20px' };
  }

  return (
    <span className={`kite-badge ${className}`} style={badgeStyles}>
      {badge}
      {qualifierText && <span className="kite-sr-only">{qualifierText}</span>}
    </span>
  );
};

KiteBadge.defaultProps = {
  badge: '',
  className: '',
};

export default KiteBadge;
