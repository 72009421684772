import * as React from 'react';

import './KiteRadioButton.scss';
import { oneWord } from '../../../utils/oneWord';

export interface IProps {
  /** Sets the displayed label text */
  label: string;
  /** Sets the `name` property of the <input>. **This should correspond with other options in the radio-group** */
  name: string;
  /** Function called after the `change` event of the checkbox. This should update the `checked` property appropriately */
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => any;
  /** Sets the `for` attribute of the <label>, and the `id` of the <input> */
  id?: string;
  /** Sets the `value` property of the <input>. */
  value?: string;
  /** Should state whether the checkbox is checked. This is required to have a controlled input */
  checked?: boolean;
  /** Subtext that will be displayed directly under the label. */
  subtext?: string;
  /** Sets the input attribute `disabled` to `true`, which prevents interaction and adjusts the styling */
  disabled?: boolean;
  /** Button orientation, when set to 'column' buttons are displayed vertically */
  buttonOrientation?: 'column' | 'row';
  /** Determines whether the focus should default to the first element. */
  anyOthersChecked?: boolean;
  /** First radio button */
  isFirstButton?: boolean;
}

const KiteRadioButton = ({
  id,
  label,
  name,
  buttonOrientation,
  value,
  checked,
  onChange,
  disabled,
  anyOthersChecked,
  subtext,
  isFirstButton,
}: IProps) => (
  <label
    htmlFor={oneWord(id || name || label)}
    className="kite-custom-control kite-custom-radio kite-radio__button"
    style={
      buttonOrientation === 'row'
        ? { marginBottom: 0, paddingLeft: '30px', marginRight: '20px' }
        : {}
    }
  >
    <input
      type="radio"
      id={oneWord(id || name || label)}
      name={name}
      value={value || label}
      checked={checked}
      onChange={onChange}
      disabled={disabled}
      className="kite-custom-control-input"
      aria-checked={checked}
      tabIndex={(isFirstButton && !anyOthersChecked) || checked ? 0 : -1}
    />
    <div className="kite-custom-control-indicator" />
    <span className="kite-custom-control-description">
      {label}
      {subtext && (
        <p className="kite-radio__subtext" aria-hidden="true">
          {subtext}
        </p>
      )}
    </span>
  </label>
);

KiteRadioButton.defaultProps = {
  id: '',
  value: '',

  subtext: '',
  disabled: false,
  buttonOrientation: 'row',
  anyOthersChecked: false,
  isFirstButton: false,
};

export default KiteRadioButton;
