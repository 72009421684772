import * as React from 'react';
import './KiteFontIcon.scss';

export interface IFontIconProps {
  /** Name of the icon, this can be found on the Search Icons page */
  name: string;
  /** Icon color */
  color?: string;
  /** Icon size in px, rem, em, %, this applies to height and width */
  size?: string;
  /** Margin CSS value, ex: "10px 20px 5p 15px" */
  margin?: string;
  /** Transform CSS value, ex: "rotate(90deg)" */
  transform?: string;
  /** Transition CSS value, ex: "all ease-in 0.4s" */
  transition?: string;
  /** Any additional classNames */
  className?: string;
  /** Function to be called on icon click */
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => any;
  /** Aria label used for screen readers when icon is being used as a button */
  ariaLabel?: string;
  /** Red badge for alerting the user that they have unread notifications */
  badge?: string;
}

/**
 * Older font icons, use KiteIcon (SVG) instead whenever possible.
 */
const KiteFontIcon = ({
  name,
  color,
  size,
  margin,
  transform,
  transition,
  className,
  onClick,
  ariaLabel,
  badge,
}: IFontIconProps) => {
  let styles = {};

  if (size) styles = { ...styles, height: size, width: size };
  if (margin) styles = { ...styles, margin };
  if (color) styles = { ...styles, color };
  if (onClick) styles = { ...styles, cursor: 'pointer' };

  let iconStyles = {};
  if (size)
    iconStyles = { ...iconStyles, fontSize: size, height: size, width: size };
  if (transform) iconStyles = { ...iconStyles, transform };
  if (transition) iconStyles = { ...iconStyles, transition };

  let badgeStyles = {};
  if (badge && badge.length === 2) {
    badgeStyles = { ...badgeStyles, borderRadius: '40%', right: '-12px' };
  }
  if (badge && badge.length > 2) {
    badgeStyles = { ...badgeStyles, borderRadius: '40%', right: '-20px' };
  }

  return (
    <>
      {onClick ? (
        <button
          type="button"
          className={`kite-font-icon ${className}`}
          style={styles}
          onClick={onClick}
          aria-label={ariaLabel}
        >
          {badge && (
            <span className="kite-font-icon__badge" style={badgeStyles}>
              {badge}
            </span>
          )}
          <span className={`si si-${name}`} style={iconStyles} />
        </button>
      ) : (
        <span
          className={`kite-font-icon ${className}`}
          style={styles}
          role="img"
          aria-label={ariaLabel}
          aria-hidden={ariaLabel ? 'false' : 'true'}
        >
          {badge && (
            <span className="kite-font-icon__badge" style={badgeStyles}>
              {badge}
            </span>
          )}
          <span className={`si si-${name}`} style={iconStyles} />
        </span>
      )}
    </>
  );
};

KiteFontIcon.defaultProps = {
  size: '18px',
  margin: '',
  transform: '',
  transition: '',
  className: '',
  onClick: null,
  ariaLabel: '',
  badge: '',
};

export default KiteFontIcon;
