import * as React from 'react';

import KiteTooltip from '../../tooltips/KiteTooltip/KiteTooltip';
import KiteRadioButton from '../KiteRadioButton/KiteRadioButton';
import { oneWord } from '../../../utils/oneWord';

import './KiteRadio.scss';

export interface IRadioButton {
  /** Sets the displayed label text */
  label: string;
  /** Sets the `for` attribute of the <label>, and the `id` of the <input> */
  id?: string;
  /** Sets the `value` property of the <input>. */
  value?: string;
  /** Should state whether the checkbox is checked. This is required to have a controlled input */
  checked?: boolean;
  /** Subtext that will be displayed directly under the label. */
  subtext?: string;
}

export interface IRadioProps {
  /** Array of radio button objects. Each object must have a label property. Id and value properties are optional, if not passed they will default to the same value as the label. An optional checked property may be used for making the radio buttons a controlled input. */
  radioButtons: IRadioButton[];
  /** Sets the label text for the entire form group */
  groupLabel: string;
  /** Sets the `name` property of the <input>. **This should correspond with other options in the radio-group** */
  name: string;
  /** Function called after the `change` event of the checkbox. This should update the `checked` property appropriately */
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => any;
  /** Sets the input attribute `disabled` to `true`, which prevents interaction and adjusts the styling */
  disabled?: boolean;
  /** A single string of class names to be added to the outer component element. If adding multiple classes, just put them in a single, space-seperated string. */
  className?: string;
  /** Button orientation, when set to 'column' buttons are displayed vertically */
  buttonOrientation?: 'column' | 'row';
  /** Content that lives inside tooltip, this may be a string or some JSX, tooltip will not show up if this prop is not passed */
  tooltip?: React.ReactNode | string;
  /** Optional margin prop, must be valid CSS */
  margin?: string;
}

/**
 * A controlled radio input. The `onChange` function should update the `checked` prop.  [View the design specifications](https://company-14496.frontify.com/d/xETwq0XBaQWU/kite-web-ui-guidelines#/components/selection-controls)
 */
const KiteRadio = ({
  radioButtons,
  name,
  onChange,
  disabled,
  className,
  groupLabel,
  buttonOrientation,
  tooltip,
  margin,
}: IRadioProps) => {
  const anyOthersChecked = radioButtons.slice(1).some(btn => btn.checked);
  const displayRadioButtons = radioButtons.map(
    ({ id, value, checked, label, subtext }, i) => {
      const isFirstButton = i === 0;
      return (
        <React.Fragment key={id || label}>
          <KiteRadioButton
            id={id || oneWord(value) || oneWord(label)}
            label={label}
            name={name}
            buttonOrientation={buttonOrientation}
            value={value}
            checked={checked}
            onChange={onChange}
            disabled={disabled}
            anyOthersChecked={anyOthersChecked}
            subtext={subtext}
            isFirstButton={isFirstButton}
          />
        </React.Fragment>
      );
    }
  );

  let styles = {};
  styles = margin ? { ...styles, margin } : styles;

  return (
    <div
      className={`kite-radio kite-form-group ${className}`}
      style={styles}
      role="group"
      aria-labelledby={oneWord(groupLabel || name)}
    >
      {groupLabel && (
        <div className="kite-radio__group-label">
          <span id={oneWord(groupLabel || name)}>{groupLabel}</span>
          {tooltip && (
            <KiteTooltip ariaLabel={`${groupLabel} tooltip`}>
              {tooltip}
            </KiteTooltip>
          )}
        </div>
      )}

      <div
        className="kite-radio__button-container"
        style={buttonOrientation === 'row' ? { flexDirection: 'row' } : {}}
      >
        {displayRadioButtons}
      </div>
    </div>
  );
};

KiteRadio.defaultProps = {
  groupLabel: '',
  disabled: false,
  className: '',
  label: '',
  buttonOrientation: 'column',
  radioButtons: [],
  tooltip: '',
  margin: '',
};

export default KiteRadio;
