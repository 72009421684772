import * as React from 'react';
import classNames from 'classnames';
import './KiteTabs.scss';

export interface ITabsProps {
  /** An arrayOf of strings, these will be the tab labels */
  tabs: string[];
  /** This is the current tab you are on, usually passed in from state or props */
  currentTab: string;
  /** This method will handle selecting a new tab, it should take in the new tab string as an argument */
  onSelectTab: (tab: string) => any;
  /** This styling propery follows flexbox justifiy-content convent, it defaults to flex-start (left aligned) */
  justify?: string;
  /** Margin for the outer most element, must be valid CSS */
  margin?: string;
  /** Max width for the outer element */
  maxWidth?: string;
  /** Additional classNames passed to component */
  className?: string;
  /** Option name for the tablist, only to be used if there are multiple tablists on a page */
  ariaLabel?: string;
}

/**
 * Tabs organize and enable navigation between similar, localized sets of information. Unlike the Navigation Bar (navbar), tabs do not allow navigation between separate pages of content and have a different keyboard interaction model View the design standard [here](https://company-14496.frontify.com/d/xETwq0XBaQWU/kite-web-ui-guidelines#/components/tabs)
 */
const KiteTabs = ({
  currentTab,
  tabs,
  onSelectTab,
  justify,
  margin,
  maxWidth,
  className,
  ariaLabel,
}: ITabsProps) => {
  const handleTabKeydown = (e, tab) => {
    switch (e.keyCode) {
      // right & down arrows
      case 39:
      case 40:
        e.preventDefault();
        if (e.target.nextElementSibling) {
          e.target.nextElementSibling.focus();
        }
        break;
      // left & up arrows
      case 37:
      case 38:
        e.preventDefault();
        if (e.target.previousElementSibling) {
          e.target.previousElementSibling.focus();
        }
        break;
      // spacebar & enter keys
      case 32:
      case 13:
        e.preventDefault();
        onSelectTab(tab);
        break;
      default:
        break;
    }
  };

  let styles = {};
  styles = justify ? { ...styles, justifyContent: justify } : styles;
  styles = margin ? { ...styles, margin } : styles;
  styles = maxWidth ? { ...styles, maxWidth } : styles;

  return (
    <div
      className={`kite-tabs ${className}`}
      role="tablist"
      aria-label={ariaLabel ? ariaLabel : ''}
      style={styles}
    >
      {tabs.map((tab, i) => (
        <button
          type="button"
          role="tab"
          aria-selected={currentTab === tab}
          tabIndex={i === 0 ? 0 : -1}
          className={classNames({
            'kite-tabs__tab': true,
            'kite-tabs__tab--active': currentTab === tab,
          })}
          key={tab}
          onClick={() => onSelectTab(tab)}
          onKeyDown={e => handleTabKeydown(e, tab)}
        >
          <span
            data-value={tab}
            tabIndex={-1}
            className={`kite-tabs__tab-content`}
          >
            {tab}
          </span>
        </button>
      ))}
    </div>
  );
};

KiteTabs.defaultProps = {
  justify: 'flex-start',
  currentTab: '',
  tabs: [],
  onSelectTab: null,
  margin: '',
  maxWidth: '',
  className: '',
};

export default KiteTabs;
