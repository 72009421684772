import * as React from 'react';

import KiteTooltip from '../../tooltips/KiteTooltip/KiteTooltip';

import './KiteSelect.scss';
import { oneWord } from '../../../utils/oneWord';

export interface ISelectProps {
  /** Children (option elements for the selection options) */
  children: React.ReactNode | string;
  /** Sets the `for` attribute of the <label>, and the `id` of the <select> */
  id: string;
  /** Sets the displayed label text  */
  label: string;
  /** Sets the `name` property of the <select> */
  name: string;
  /** Should state the current value of the element, and coincide with an `<option>`'s value. This is required to have a controlled input. */
  value: string;
  /** Function called after the `change` event of the element. This should update the `value` prop appropriately. */
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => any;
  /** Displayed as an errorMessage message below the input. */
  errorMessage?: string;
  /** Sets the input attribute `disabled` to `true`, which prevents interaction and adjusts the styling */
  disabled?: boolean;
  /** A single string of class names to be added to the outer component element. If adding multiple classes, just put them in a single, space-seperated string. */
  className?: string;
  /** Placeholder text */
  placeholder?: string;
  /** Optional margin prop, must be valid CSS */
  margin?: string;
  /** Optional maxWidth prop, must be valid CSS, set to 100% to take up the full width */
  maxWidth?: string;
  /** Content that lives inside tooltip, this may be a string or some JSX, tooltip will not show up if this prop is not passed */
  tooltip?: React.ReactNode | string;
  /** Props passed directly to the select input */
  inputProps?: object;
}

/**
 * A standard `<select>` element. All `<option>`s must be passed in as children.  View the design standard [here](https://company-14496.frontify.com/d/xETwq0XBaQWU/kite-web-ui-guidelines#/components/text-fields-amp-select-menus)
 */
const KiteSelect = ({
  id,
  label,
  name,
  value,
  onChange,
  errorMessage,
  disabled,
  className,
  children,
  placeholder,
  margin,
  maxWidth,
  tooltip,
  inputProps,
}: ISelectProps) => {
  let styles = {};
  styles = margin ? { ...styles, margin } : styles;
  styles = maxWidth ? { ...styles, maxWidth } : styles;

  return (
    <div className={`kite-form-group  kite-select ${className}`} style={styles}>
      <div className="kite-select__label-container">
        {label && (
          <label
            htmlFor={oneWord(id || name || label)}
            className="kite-select__label"
          >
            {label}
          </label>
        )}
        {tooltip && (
          <KiteTooltip
            ariaLabel={`${label} tooltip`}
            ariaControls={oneWord(id || name || label)}
          >
            {tooltip}
          </KiteTooltip>
        )}
      </div>
      <select
        id={oneWord(id || name || label)}
        name={name}
        value={value}
        onChange={onChange}
        disabled={disabled}
        className={`kite-select__select kite-form-control ${errorMessage &&
          'kite-form-control-danger'}`}
        placeholder={placeholder}
        style={label ? { marginTop: '4px' } : {}}
        {...inputProps}
      >
        {placeholder && (
          <option
            value=""
            disabled
            style={{ color: '#999' }}
            className="kite-select__option"
          >
            {placeholder}
          </option>
        )}
        {children}
      </select>
      {errorMessage && (
        <div className="kite-form-control-feedback">{errorMessage}</div>
      )}
    </div>
  );
};

KiteSelect.defaultProps = {
  errorMessage: '',
  label: '',
  disabled: false,
  className: '',
  placeholder: '',
  margin: '',
  maxWidth: '',
  tooltip: '',
  inputProps: {},
};

export default KiteSelect;
