import * as React from 'react';

import './KiteStyles.scss';

const KiteStyles = () => (
  <div>
    <h1>KiteStyles</h1>
  </div>
);

export default KiteStyles;
