import * as React from 'react';
import classNames from 'classnames';

import KiteNavDrawer from '../KiteNavDrawer/KiteNavDrawer';
import KiteNavExpansion from '../KiteNavExpansion/KiteNavExpansion';
import KiteMenuIcon from '../KiteMenuIcon/KiteMenuIcon';
import KiteIcon from '../../icons/KiteIcon/KiteIcon';

import './KiteNavBar.scss';

export interface INavUser {
  name: string;
  [x: string]: any;
}

export interface IMenuButton {
  label: string;
  render: React.ReactNode;
}

export interface INavbarProps {
  /** Array of objects, each object must have a Label property and can optionally have a render property. If a render property is passed, the link will render the JSX that is the render property's value. */
  navLinks: React.ReactNode;
  /** Menu buttons that will be displayed in the top right corner. This must be an array of objects that contain label and render properties. Render should be a button element and the onClick for these buttons will go on that button. */
  menuButtons?: IMenuButton[];
  /** The defaultActiveLink will have the active link styling when the component loads. */
  defaultActiveLink?: string;
  /** This function will be called when the search button is clicked. */
  onSearchClick?: () => any;
  /** This will change the component to dark mode. */
  useDark?: boolean;
  /** This prop enables you to control the activeLink in a containing component, but is not required. */
  drawerOpen?: boolean;
  /** This function will be called when the sign out button in the drawer is clicked. It will receive the user object as an argument. */
  onSignOutClick?: (user?: INavUser | null) => any;
  /** The Nav Drawer will show the user name if the user object is passed with a name property. */
  user?: INavUser | null;
  /** This content will go inside the nav drawer. */
  drawerChildren?: React.ReactNode;
  /** This content will go inside the nav expansion */
  expansionChildren?: React.ReactNode;
  /** Expansion title to be displayed when the links are collpsed down into the nav expansion. This should ideally change to match the current link. */
  expansionTitle?: string;
  /** Logo image to be display in the top center. You will need to import your logo image (import reactLogo from './images/reactLogo.png';) and then pass the import into this prop. This will default to looking for Spectrum_Logo_RGB.png in your public directory which can be saved from this documentation by right clicking on the logo image and selecting "save image as".  */
  logoImage: string;
}

export interface INavbarState {
  drawerOpen: boolean;
}

class KiteNavBar extends React.Component<INavbarProps, INavbarState> {
  state = {
    drawerOpen: false,
  };

  static defaultProps = {
    menuButtons: [],
    onSearchClick: null,
    useDark: false,
    drawerOpen: false,
    onSignOutClick: null,
    user: null,
    expansionTitle: '',
  };

  render() {
    const {
      navLinks,
      menuButtons,
      onSearchClick,
      useDark,
      user,
      onSignOutClick,
      drawerChildren,
      expansionChildren,
      expansionTitle,
      logoImage,
    } = this.props;

    // eslint-disable-next-line react/destructuring-assignment
    const drawerOpen = this.props.drawerOpen || this.state.drawerOpen;

    const displayMenuButtons = menuButtons
      ? menuButtons.map(({ label, render }, i) => {
          if (i > 0) {
            return (
              <React.Fragment key={label}>
                <span>|</span>
                {render}
              </React.Fragment>
            );
          }

          return <span key={label}>{render}</span>;
        })
      : [];

    return (
      <header
        className={classNames({ 'kite-nav': true, 'kite-nav--dark': useDark })}
      >
        <KiteNavDrawer
          onClose={() => this.setState({ drawerOpen: false })}
          user={user}
          onSignOut={() => (onSignOutClick ? onSignOutClick(user) : null)}
          isOpen={drawerOpen}
        >
          {drawerChildren}
        </KiteNavDrawer>
        <div className="kite-nav__top-row">
          <div className="kite-nav__menu-button-group">
            <KiteMenuIcon
              onClick={() => this.setState({ drawerOpen: true })}
              isOpen={drawerOpen}
              color={useDark ? '#D8DDE6' : ''}
            />
            <h3>MENU</h3>
          </div>
          {useDark ? (
            <img
              src={logoImage || '/Spectrum_Logo_Rev_RGB.png'}
              alt="spectrum logo"
              className="kite-nav__logo-image"
            />
          ) : (
            <img
              src={logoImage || '/Spectrum_Logo_RGB.png'}
              alt="spectrum logo"
              className="kite-nav__logo-image"
            />
          )}
          <div className="kite-nav__notification-group">
            {displayMenuButtons}
          </div>
        </div>
        <div className="kite-nav__bottom-row">{navLinks}</div>
        <KiteIcon
          name="search"
          size="30px"
          className="kite-nav__search-icon"
          onClick={onSearchClick}
          color={useDark ? '#D8DDE6' : ''}
          ariaLabel="search"
        />
        <KiteNavExpansion
          title={expansionTitle}
          className="kite-nav__nav-expansion"
          useDark={useDark}
        >
          {expansionChildren}
        </KiteNavExpansion>
      </header>
    );
  }
}

export default KiteNavBar;
