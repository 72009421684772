import * as React from 'react';
import classNames from 'classnames';

import KiteCard from '../../cards/KiteCard/KiteCard';
import KiteIcon from '../../icons/KiteIcon/KiteIcon';

import './KiteSingleLinePanel.scss';

export interface ISinglePanelProps {
  /** The props controlling whether the panel is expanded or not. */
  isOpen: boolean;
  /** A function to execute when the title or arrow are clicked. This should probably toggle the value of *isOpen* */
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => any;
  /** The text to be displayed as the title of the card. */
  title: string;
  /** Children */
  children: React.ReactNode | string;
  /** Any additional classes that should be added onto the KiteCard wrapper. */
  className?: string;
  /** Will add an indicator to the top left corner. Indicator will be a different color depending on the type */
  indicator?: 'action' | 'info' | 'alert' | 'confirm' | '';
  /** Padding in the content section, can be useful for setting to 0 if dividers are needed. */
  contentPadding?: string;
  /** Customize the max height for the content drawer when open. Used panels with an extremely tall amount of content. */
  maxContentHeight?: string;
}

/**
 * A card that initially only displays the title and an arrow, but when clicked expands to display hidden content. All hidden content should be passed in as children to the component. View the design standard [here](https://company-14496.frontify.com/d/xETwq0XBaQWU/kite-web-ui-guidelines#/components/expansion-panels)
 */
const KiteSingleLinePanel = ({
  isOpen,
  title,
  onClick,
  className,
  children,
  indicator,
  contentPadding,
  maxContentHeight,
}: ISinglePanelProps) => {
  let contentStyles = {};
  if (contentPadding)
    contentStyles = { ...contentStyles, padding: contentPadding };
  if (isOpen && maxContentHeight)
    contentStyles = { ...contentStyles, maxHeight: maxContentHeight };

  return (
    <KiteCard
      isActive={isOpen}
      className={classNames({
        'kite-single-exp': true,
        'kite-single-exp--open': isOpen,
        [className || '']: className,
      })}
    >
      <button
        type="button"
        className="kite-single-exp__title"
        onClick={onClick}
        aria-expanded={isOpen ? 'true' : 'false'}
        aria-label={title}
      >
        {indicator && (
          <span
            className={`kite-single-exp__indicator 
          kite-single-exp__indicator--${indicator}`}
          />
        )}
        <span className="kite-single-exp__title-text">{title}</span>
        <KiteIcon
          name="chevron-down-circle-f"
          size="18px"
          className="kite-single-exp__title-icon"
        />
      </button>

      <div className="kite-single-exp__content" style={contentStyles}>
        {children}
      </div>
    </KiteCard>
  );
};

KiteSingleLinePanel.defaultProps = {
  className: '',
  maxContentHeight: '',
  indicator: '',
};

export default KiteSingleLinePanel;
