import * as React from 'react';
import classNames from 'classnames';

import KiteLoader from '../KiteLoader/KiteLoader';

import './KiteLoaderOverlay.scss';

export interface ILoaderOverlayProps {
  /** Will show the loader if true. */
  showLoader: boolean;
  /** Additional classNames */
  className: string;
  /** If true, the color will be #fff, otherwise the default color of the spinner is #0073d1. */
  useDark: boolean;
  /** Size prop will be applied to the loader circle. Should be set to 'small', 'medium', or 'large' to follow Kite design spec. Valid CSS size is still supported as well. */
  size: string;
  /** Set to false if you do not want to display the percent. Percent will automatically not be shown on size 'small'. */
  showPercent: boolean;
  /** Loading state status. If no value is passed it will be in a loading state. If success or failure passed it will reflect that status. */
  status: '' | 'success' | 'failure';
  /** Title displayed under the loader. */
  loaderTitle: string;
  /** Secondary message displayed under the loader. */
  secondaryMessage: string;
  /** Duration message displayed under the loader. Should tell the user how much time is remaining. */
  durationMessage: string;
  /** Will turn the loader into determinite mode. Loader will then display the percent loaded. */
  percent:
    | 0
    | 1
    | 2
    | 3
    | 4
    | 5
    | 6
    | 7
    | 8
    | 9
    | 10
    | 11
    | 12
    | 13
    | 14
    | 15
    | 16
    | 17
    | 18
    | 19
    | 20
    | 21
    | 22
    | 23
    | 24
    | 25
    | 26
    | 27
    | 28
    | 29
    | 30
    | 31
    | 32
    | 33
    | 34
    | 35
    | 36
    | 37
    | 38
    | 39
    | 40
    | 41
    | 42
    | 43
    | 44
    | 45
    | 46
    | 47
    | 48
    | 49
    | 50
    | 51
    | 52
    | 53
    | 54
    | 55
    | 56
    | 57
    | 58
    | 59
    | 60
    | 61
    | 62
    | 63
    | 64
    | 65
    | 66
    | 67
    | 68
    | 69
    | 70
    | 71
    | 72
    | 73
    | 74
    | 75
    | 76
    | 77
    | 78
    | 79
    | 80
    | 81
    | 82
    | 83
    | 84
    | 85
    | 86
    | 87
    | 88
    | 89
    | 90
    | 91
    | 92
    | 93
    | 94
    | 95
    | 96
    | 97
    | 98
    | 99
    | 100;
}

const KiteLoaderOverlay = ({
  showLoader,
  className,
  useDark,
  size,
  percent,
  showPercent,
  status,
  loaderTitle,
  secondaryMessage,
  durationMessage,
}: ILoaderOverlayProps) => (
  <div
    className={classNames({
      'kite-loader-overlay': true,
      'kite-loader-overlay__show-loader--light': showLoader && !useDark,
      'kite-loader-overlay__show-loader--dark': showLoader && useDark,
      [className]: className,
    })}
  >
    <KiteLoader
      useLight={useDark}
      size={size}
      percent={percent}
      showPercent={showPercent}
      status={status}
      loaderTitle={loaderTitle}
      secondaryMessage={secondaryMessage}
      durationMessage={durationMessage}
    />
  </div>
);

KiteLoaderOverlay.defaultProps = {
  showLoader: true,
  useDark: false,
  size: 'large',
  className: '',
  percent: undefined,
  showPercent: true,
  status: '',
  loaderTitle: '',
  secondaryMessage: '',
  durationMessage: '',
  ctaButtonProps: { size: 'large', color: 'primary' },
};

export default KiteLoaderOverlay;
